import { registerEditorElement } from './Instances';
import {
    generateId,
    getBrailleDocument,
    getClosestPage,
    isEditorElement,
    isInside,
} from '../EditorUtil';
import {
    breakParagraphsEditorElementContainer,
    clearEditorElementLinesCount,
    createInvisibleParagraphBreak,
    elementCanBeInsertedAtSelection,
    fixInvisibleParagraphBreak,
    markLines,
    removeInvisibleParagraphBreak,
    setEditorElementLinesCount,
} from '../EditorElements';
import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import { getCurrentPage } from '../PageManipulation';
import { MARK_CHAR } from '../../../../../conversion/braille/CharMap';
import {
    backPropagateBreaksToText,
    convertElementToBraille,
} from '../../../../../conversion/braille/HtmlToBraille';
import { getBrailleParagraphs } from '../BrailleView';
import { BrailleFacilConversionFlag } from '../../../../../conversion/txt/BrailleFacilConversionFlag';
import { getElementOfPageNotFixed } from '../../ExcessLinesControlModule';
import { getCaretPosition } from '../CaretPath';
import { extractRecursively } from '../../../../../conversion/txt/HtmlToBrailleFacil';

export const EDITOR_ELEMENT_FOOTNOTE = 'EDITOR_ELEMENT_FOOTNOTE';
export const EDITOR_ELEMENT_FOOTNOTE_MARK = 'EDITOR_ELEMENT_FOOTNOTE_MARK';
export const EDITOR_ELEMENT_FOOTNOTE_ITEM = 'EDITOR_ELEMENT_FOOTNOTE_ITEM';

export function getListSubmenuItems(editor) {
    const { editorElements } = editor.custom.coreModule;
    return [
        {
            type: 'menuitem',
            // I18N
            text: 'Notas',
            onAction: function () {
                editorElements.insertElementAtCursor(
                    EDITOR_ELEMENT_FOOTNOTE_MARK,
                );
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Letras estrangeiras',
            onAction: function () {
                editorElements.insertElementAtCursor(
                    EDITOR_ELEMENT_FOOTNOTE_MARK,
                    {
                        foreignLetters: true,
                    },
                );
            },
        },
    ];
}

const acuteAccents = ['á', 'é', 'í', 'ó', 'ú', 'ý'];
const graveAccents = ['à', 'è', 'ì', 'ò', 'ù'];
const circumflexAccents = ['â', 'ê', 'î', 'ô', 'û'];
const diaeresisAccents = ['ä', 'ë', 'ï', 'ö', 'ü', 'ÿ'];
const tildeAccents = ['ã', 'õ', 'ñ'];

const foreignLetterBrailleMap = {
    "'": '*',
    '`': '?',
    '^': '^',
    '"': '¬',
    '~': '~',
};

// I18N
const accentsPtBrNameMap = {
    // I18N
    "'": 'acento agudo',
    // I18N
    '`': 'acento grave',
    // I18N
    '^': 'acento circunflexo',
    // I18N
    '"': 'trema',
    // I18N
    '~': 'til',
};

const accentsMap = {
    "'": acuteAccents,
    '`': graveAccents,
    '^': circumflexAccents,
    '"': diaeresisAccents,
    '~': tildeAccents,
};

/**
 * @param text {string}
 * @returns {{text: string[], accent: string}}
 */
function separateAccent(text) {
    for (const [accent, chars] of Object.entries(accentsMap)) {
        for (const char of chars) {
            const charIndex = text.indexOf(char);
            if (charIndex !== -1) {
                const baseChar = char
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '');
                return {
                    text: [
                        text.slice(0, charIndex),
                        baseChar,
                        text.slice(charIndex + 1),
                    ],
                    accent,
                };
            }
        }
    }
    return { text: [text], accent: '' }; // Return original text and empty accent if no accented character is found
}

/**
 * This function is slow, does not keep a map in memory
 * Change if usage will be frequent
 * @param text {string}
 * @returns {string}
 */
function revertAccent(text) {
    const baseCharMap = {};
    for (const [accent, chars] of Object.entries(accentsMap)) {
        chars.forEach((char) => {
            const baseChar = char
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
            baseCharMap[baseChar + accent] = char;
            if (foreignLetterBrailleMap[accent]) {
                baseCharMap[foreignLetterBrailleMap[accent] + baseChar] = char;
            }
        });
    }
    return text.replace(/([*?¬~^])(\w)/gi, (match, char, accent) => {
        return baseCharMap[char + accent] || match;
    });
}

/**
 * @param page {HTMLElement}
 * @returns {HTMLElement}
 */
function getFootnoteElementFromPage(page) {
    return page.querySelector('editor-element[type="footnote"]');
}

/**
 * @param node {Node | HTMLElement}
 * @returns {boolean}
 */
function isEditorElementFootnote(node) {
    return node?.getAttribute && node.getAttribute('type') === 'footnote';
}

/**
 * @param node {Node | HTMLElement}
 * @returns {boolean}
 */
function isEditorElementFootnoteMark(node) {
    return node?.getAttribute && node.getAttribute('type') === 'footnote-mark';
}

/**
 * @param node {Node | HTMLElement}
 * @returns {boolean}
 */
function isEditorElementFootnoteItem(node) {
    return node?.getAttribute && node.getAttribute('type') === 'footnote-item';
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
function isInsideEditorElementFootnote(node) {
    return isInside(
        node,
        (node) => isEditorElement(node) && isEditorElementFootnote(node),
    );
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
function isInsideEditorElementFootnoteMark(node) {
    return isInside(
        node,
        (node) => isEditorElement(node) && isEditorElementFootnoteMark(node),
    );
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
function isInsideEditorElementFootnoteItem(node) {
    return isInside(
        node,
        (node) => isEditorElement(node) && isEditorElementFootnoteItem(node),
    );
}

/**
 * @param editorElements {EditorElements}
 * @param page {HTMLElement}
 * @param footnoteElement {HTMLElement}
 */
function appendFootnoteInPage(editorElements, page, footnoteElement) {
    let lastElement = getElementOfPageNotFixed(editorElements, page, false);
    if (!lastElement && footnoteElement.parentElement !== page) {
        page.append(footnoteElement);
    } else if (lastElement?.nextElementSibling !== footnoteElement) {
        lastElement?.after(footnoteElement);
    }
}

/**
 * @implements {EditorElement}
 */
export class EditorElementFootnote {
    /**
     * @type {null | EditorCustom}
     */
    editor;
    /**
     * @type {null | EditorElements}
     */
    editorElements = null;

    /**
     * @returns {EditorElementFootnoteItem | null}
     */
    getEditorElementFootnoteItem() {
        return (
            this.editorElements.getEditorElementInstance(
                EDITOR_ELEMENT_FOOTNOTE_ITEM,
            ) ?? null
        );
    }

    /**
     * @param editor {EditorCustom}
     * @param editorElements {EditorElements}
     */
    initialize(editor, editorElements) {
        this.editor = editor;
        this.editorElements = editorElements;
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'footnote';
    }

    /**
     * @param node {Node}
     * @returns {boolean}
     */
    isNodeInsideElement(node) {
        return (
            !isInsideEditorElementFootnoteItem(node) &&
            isInsideEditorElementFootnote(node)
        );
    }

    /**
     * @returns {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.text'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isBlockingElement() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isFixedToBottom() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined}
     * @return {HTMLElement}
     */
    createEditorElement(editor = undefined) {
        const editorElement = document.createElement('editor-element');
        const idPrefix = 'editor-element-footnote';
        const elementId = generateId(editor, idPrefix);

        editorElement.setAttribute('type', 'footnote');
        editorElement.setAttribute('id', elementId);
        editorElement.setAttribute('contentEditable', 'false');

        const textContainer = document.createElement('div');
        textContainer.className = 'text';
        textContainer.setAttribute('contentEditable', 'false');
        textContainer.innerHTML = ZERO_WIDTH_NB_CHAR;
        editorElement.appendChild(textContainer);

        return editorElement;
    }

    /**
     * @return {boolean}
     */
    insertElementAtCursor() {
        throw new Error('This element cannot be inserted at cursor');
    }

    /**
     * @param element {HTMLElement}
     */
    fixMarginsWithDocument(element) {
        const brailleDocument = getBrailleDocument(this.editor);
        const { inkPageMarginLeft, inkPageMarginRight, inkPageMarginBottom } =
            brailleDocument;
        if (!isEditorElementFootnote(element)) {
            console.warn('Not a valid footnote element.', element);
            return;
        }
        element.style.paddingLeft = `${inkPageMarginLeft}mm`;
        element.style.paddingRight = `${inkPageMarginRight}mm`;
        element.style.paddingBottom = `${inkPageMarginBottom}mm`;
        if (!brailleDocument.convertedToBraille) {
            // for some reason, the invisible brs are visible at this moment and
            // the height is calculated wrong, after reconstruction in revision module
            for (const item of this.getEditorElementFootnoteItem().getElementsInContainer(
                element,
            )) {
                fixInvisibleParagraphBreak(item);
            }

            const page = getClosestPage(element);
            const height = element.offsetHeight;
            page.style.paddingBottom = `${height}px`;
        }
    }

    /**
     * @param page {HTMLElement}
     * @returns {boolean}
     */
    insertElementAtPage(page) {
        if (page.querySelector('editor-element[type="footnote"]')) {
            // only one to page
            return false;
        }

        this.editor.undoManager.transact(() => {
            const editorElement = this.createEditorElement(this.editor);
            page.appendChild(editorElement);
            page.appendChild(createInvisibleParagraphBreak());
            this.fixMarginsWithDocument(editorElement);
        });
        return true;
    }

    /**
     * @param page {HTMLElement}
     */
    removeElementFromPage(page) {
        const elements = this.getElementsInContainer(page);
        for (const element of elements) {
            element.remove();
        }
    }

    /**
     * @param container {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getElementsInContainer(container) {
        return [
            ...container.querySelectorAll('editor-element[type="footnote"]'),
        ];
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElement(element) {
        let textContainer = element.querySelector('.text');
        if (!textContainer) {
            const newElement = this.createEditorElement();
            element.replaceWith(newElement);
        } else {
            if (!textContainer.textContent) {
                textContainer.innerHTML = ZERO_WIDTH_NB_CHAR;
            }
        }
        fixInvisibleParagraphBreak(element);
        this.fixMarginsWithDocument(element);
        const page = getClosestPage(element);
        if (page) {
            appendFootnoteInPage(this.editorElements, page, element);
        }
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        const rawConversion = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );

        const editorElementFootnoteItem = this.getEditorElementFootnoteItem();

        let itemsData = '';
        const items = element.querySelectorAll(
            'editor-element[type="footnote-item"]',
        );
        for (const item of items) {
            itemsData +=
                editorElementFootnoteItem.convertToBraille(
                    item,
                    flags,
                    editorElements,
                    brailleDocument,
                ) ?? '';
            itemsData += '\r\n';
        }
        itemsData = itemsData.trimEnd();

        if (!rawConversion) {
            let output = '<R+>\r\n';
            output += itemsData;
            output += '</R+>\r\n';
            return output;
        }

        return (
            MARK_CHAR.FOOT_PAGE_MARK +
            markLines(
                ':'.repeat(brailleDocument.brailleCellColCount),
                MARK_CHAR.RAW_DATA,
            ) +
            '\r\n' +
            itemsData
        );
    }

    /**
     * @returns {string[]}
     */
    getContextMenu() {
        return [];
    }
}

/**
 * @implements {EditorElement}
 */
export class EditorElementFootnoteMark {
    /**
     * @type {null | EditorCustom}
     */
    editor = null;
    /**
     * @type {null | EditorElements}
     */
    editorElements = null;

    /**
     * @type {null | HTMLElement}
     */
    lastElementSelectedContextMenu = null;

    /**
     * @return {EditorElementFootnote | null}
     */
    getEditorElementFootnote() {
        return (
            this.editorElements.getEditorElementInstance(
                EDITOR_ELEMENT_FOOTNOTE,
            ) ?? null
        );
    }

    /**
     * @return {EditorElementFootnoteItem | null}
     */
    getEditorElementFootnoteItem() {
        return (
            this.editorElements.getEditorElementInstance(
                EDITOR_ELEMENT_FOOTNOTE_ITEM,
            ) ?? null
        );
    }

    /**
     * @param page {HTMLElement}
     * @private
     */
    _elementRemoved(page) {
        const footnoteElement = getFootnoteElementFromPage(page);
        const editorElementFootnoteItem = this.getEditorElementFootnoteItem();
        if (!editorElementFootnoteItem) return;
        for (const element of editorElementFootnoteItem.getElementsInContainer(
            page,
        )) {
            editorElementFootnoteItem.removeOrphan(page, element);
        }
        editorElementFootnoteItem.reorderElements(footnoteElement, page);
    }

    /**
     * @param element {HTMLElement}
     * @private
     */
    _beforeRemove(element) {
        const foreignAccent = element.getAttribute('data-foreign-accent');
        if (!foreignAccent) return;

        let textSibling = null;
        /**
         * @type {Element | Node}
         */
        let walk = element.previousSibling;
        while (walk) {
            if (walk?.tagName === 'BR') break;
            if (walk.textContent.indexOf(foreignAccent) !== -1) {
                textSibling = walk;
                break;
            }
            walk = walk.previousSibling;
        }

        if (textSibling) {
            if (textSibling.textContent.indexOf(foreignAccent) === -1) {
                console.debug(
                    `Cannot revert foreign accent from text ${textSibling.textContent}`,
                );
            } else {
                textSibling.textContent = revertAccent(textSibling.textContent);
            }
        }
    }

    /**
     * @param editor {EditorCustom}
     * @param editorElements {EditorElements}
     */
    initialize(editor, editorElements) {
        this.editor = editor;
        this.editorElements = editorElements;

        const self = this;
        this.editor.on('editorElementBeforeRemove@footnote-mark', (e) => {
            /**
             * @type {HTMLElement}
             */
            const element = e?.element;
            self._beforeRemove(element);
        });

        this.editor.on('editorElementRemoved@footnote-mark', (e) => {
            const page = e?.page;
            if (page) {
                self._elementRemoved(page);
            }
        });

        editor.ui.registry.addNestedMenuItem(
            'customEditorElementFootnoteMarkChangeModel',
            {
                // I18N
                text: 'Alterar modelo',
                icon: 'footnote',
                getSubmenuItems: function () {
                    const element = self.lastElementSelectedContextMenu;
                    const currentMark = element.textContent.trim();
                    const page = getClosestPage(element);
                    if (!page) return [];
                    return self.getUniqueMarks(page).map((mark) => ({
                        type: 'togglemenuitem',
                        text: mark,
                        onSetup: (api) => {
                            api.setActive(mark === currentMark);
                        },
                        onAction: () => {
                            const refreshedElement = self.editor.dom.get(
                                element.getAttribute('id'),
                            );
                            self.changeMarkModel(refreshedElement, page, mark);
                        },
                    }));
                },
            },
        );
    }

    /**
     * @param element {HTMLElement}
     * @param page {HTMLElement}
     * @param newMark {string}
     */
    changeMarkModel(element, page, newMark) {
        const editorElementFootnoteItem = this.getEditorElementFootnoteItem();
        if (!editorElementFootnoteItem) return;
        /**
         * @type {null | HTMLElement}
         */
        let footnoteItem = null;
        for (const elementItem of editorElementFootnoteItem.getElementsInContainer(
            page,
        )) {
            const elementItemMarkContainer = elementItem.querySelector('.mark');
            if (elementItemMarkContainer.textContent.trim() === newMark) {
                footnoteItem = elementItem;
                break;
            }
        }
        if (!footnoteItem) {
            const footnoteElement = getFootnoteElementFromPage(page);

            footnoteItem = this.getEditorElementFootnoteItem().addFootnoteItem(
                footnoteElement,
                newMark,
                '',
                element.getAttribute('data-foreign-accent'),
            );
        }
        const caretPosition = getCaretPosition(this.editor);
        this.editor.undoManager.transact(() => {
            const elementItemId = footnoteItem.getAttribute('id');
            const markContainer = element.querySelector('.text');
            markContainer.innerText = newMark;
            element.setAttribute('data-item-element', elementItemId);
        });

        /**
         * @type {PageDataChangedEvent}
         */
        const pageDataChangedEvent = {
            caretPosition,
        };
        this.editor.fire('pageDataChanged', pageDataChangedEvent);
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'footnote-mark';
    }

    /**
     * @param node {Node}
     * @returns {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementFootnoteMark(node);
    }

    /**
     * @returns {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.text'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isBlockingElement() {
        return false;
    }

    /**
     * @param editor {EditorCustom | undefined}
     * @return {HTMLElement}
     */
    createEditorElement(editor = undefined) {
        const editorElement = document.createElement('editor-element');
        const idPrefix = 'editor-element-footnote-mark';
        const elementId = generateId(editor, idPrefix);

        editorElement.setAttribute('type', 'footnote-mark');
        editorElement.setAttribute('id', elementId);
        editorElement.setAttribute('contentEditable', 'false');

        const textContainer = document.createElement('div');
        textContainer.className = 'text';
        textContainer.setAttribute('contentEditable', 'false');
        editorElement.appendChild(textContainer);

        return editorElement;
    }

    /**
     * @param page {HTMLElement}
     * @return {string[]}
     */
    getUniqueMarks(page) {
        const markSet = new Set();
        for (const editorElement of this.getElementsInContainer(page)) {
            const textContainer = editorElement.querySelector('.text');
            const mark = textContainer.innerText;
            markSet.add(mark);
        }
        return [...markSet];
    }

    /**
     * @param itemId {string}
     * @param page {HTMLElement}
     */
    getElementsByItemId(itemId, page) {
        return [...page.querySelectorAll(`[data-item-element="${itemId}"]`)];
    }

    /**
     * @param editor {EditorCustom}
     * @param data {undefined | {foreignLetters: boolean}}
     * @return {boolean}
     */
    insertElementAtCursor(editor, data = undefined) {
        const foreignLetters = data?.foreignLetters ?? false;
        let element = this.createEditorElement(editor);

        if (!elementCanBeInsertedAtSelection(editor, element)) {
            return false;
        }
        let selection = editor.selection.getContent();
        const tmpDiv = document.createElement('div');
        tmpDiv.innerHTML = selection;
        // this destroys formatting, but no time to waste right now
        selection = tmpDiv.textContent;

        /**
         * @type {undefined | string}
         */
        let footnote = undefined;
        /**
         * @type {undefined | string}
         */
        let foreignLetterAccent = undefined;

        if (foreignLetters) {
            let error = null;
            const words = selection
                .split(/\s+/g)
                .filter((word) => word.trim().length);
            if (words.length === 0) {
                // I18N
                error = 'Selecione uma palavra.';
            } else if (words.length > 1) {
                // I18N
                error = 'Selecione apenas uma palavra.';
            } else {
                const accentsRegexp = new RegExp(
                    `[${Object.values(accentsMap).join('')}]`,
                    'gi',
                );
                if (!words[0].match(accentsRegexp)) {
                    // I18N
                    error = 'A palavra selecionada não possui acento.';
                } else {
                    selection = words[0];
                }
            }

            if (error) {
                editor.notificationManager.open({
                    // I18N
                    text: error,
                    type: 'warning',
                    timeout: 5000,
                });
                return false;
            }
        }

        const elementId = element.getAttribute('id');

        editor.undoManager.transact(() => {
            if (foreignLetters) {
                const { text, accent } = separateAccent(selection);
                const brailleChar = foreignLetterBrailleMap[accent] ?? '?';
                selection =
                    text[0] + brailleChar + text[1] + text[2] + '&nbsp;';
                const accentBrName = accentsPtBrNameMap[accent] ?? '?';
                foreignLetterAccent = `${brailleChar}${text[1]}`;
                // I18N
                footnote = `${foreignLetterAccent} é igual ao ${text[1]} com ${accentBrName}.`;
                element.setAttribute(
                    'data-foreign-accent',
                    foreignLetterAccent,
                );
            }

            editor.selection.setContent(
                selection + element.outerHTML + '&nbsp;',
            );
            element = editor.dom.get(elementId);
        });

        this.getEditorElementFootnote().insertElementAtPage(
            getCurrentPage(this.editor),
        );

        const page = getClosestPage(element);

        const footnoteElement = getFootnoteElementFromPage(page);

        const textContainer = element.querySelector('.text');

        let footnoteItem = foreignLetters
            ? this.getEditorElementFootnoteItem().getElementByForeignLetterAccent(
                  page,
                  foreignLetterAccent,
              )
            : null;
        if (footnoteItem) {
            const footnoteItemMarkContainer =
                footnoteItem.querySelector('.mark');
            textContainer.innerText =
                footnoteItemMarkContainer.textContent.trimEnd();
        } else {
            const uniqueMarks = this.getUniqueMarks(page).length;
            textContainer.innerText =
                '(**' + (uniqueMarks === 1 ? '' : uniqueMarks.toString()) + ')';

            footnoteItem = this.getEditorElementFootnoteItem().addFootnoteItem(
                footnoteElement,
                textContainer.textContent,
                footnote,
                foreignLetterAccent,
            );
        }

        element.setAttribute(
            'data-item-element',
            footnoteItem.getAttribute('id'),
        );

        this.getEditorElementFootnoteItem().reorderElements(
            footnoteElement,
            page,
        );

        return true;
    }

    /**
     * @param element {HTMLElement}
     * @return {string}
     */
    convertToBraille(element) {
        const textContainer = element.querySelector('.text');
        return textContainer.textContent
            .trimEnd()
            .replaceAll('(', '`(')
            .replaceAll(')', '`)');
    }

    /**
     * @param container {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getElementsInContainer(container) {
        return [
            ...container.querySelectorAll(
                'editor-element[type="footnote-mark"]',
            ),
        ];
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElement(element) {
        const page = getClosestPage(element);
        const editorElements = this.getElementsInContainer(page);
        if (editorElements.length) {
            this.getEditorElementFootnote().insertElementAtPage(page);
        } else {
            this.getEditorElementFootnote().removeElementFromPage(page);
        }
        let textContainer = element.querySelector('.text');
        if (!textContainer) {
            const newElement = this.createEditorElement();
            element.replaceWith(newElement);
        }
        if (textContainer.textContent.endsWith(' ')) {
            textContainer.textContent = textContainer.textContent.trimEnd();
        }
    }

    /**
     * @param element {HTMLElement}
     * @returns {string[]}
     */
    getContextMenu(element) {
        this.lastElementSelectedContextMenu = element;
        return [
            'customContextMenuRemove',
            '|',
            'customEditorElementFootnoteMarkChangeModel',
        ];
    }
}

/**
 * @implements {EditorElement}
 */
export class EditorElementFootnoteItem {
    /**
     * @type {null | EditorCustom}
     */
    editor = null;

    /**
     * @type {null | EditorElements}
     */
    editorElements = null;

    /**
     * @param editor {EditorCustom}
     * @param editorElements {EditorElements}
     */
    initialize(editor, editorElements) {
        this.editor = editor;
        this.editorElements = editorElements;

        const self = this;
        this.editor.on(
            `editorElementBlurred@${this.getEditorElementType()}`,
            (e) => {
                const element = e?.element;
                const page = getClosestPage(element);
                if (!page) return;
                const footnoteElement = getFootnoteElementFromPage(page);
                self.reorderElements(footnoteElement, page);
            },
        );
    }

    /**
     * @returns {EditorElementFootnoteMark | null}
     */
    getEditorElementFootnoteMark() {
        return (
            this.editorElements.getEditorElementInstance(
                EDITOR_ELEMENT_FOOTNOTE_MARK,
            ) ?? null
        );
    }

    /**
     * @returns {boolean}
     */
    isFixedToBottom() {
        return true;
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'footnote-item';
    }

    /**
     * @param node {Node}
     * @returns {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementFootnoteItem(node);
    }

    /**
     * @returns {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.mark', '.text'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isBlockingElement() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined}
     * @param footnote {string | undefined}
     * @return {HTMLElement}
     */
    createEditorElement(editor = undefined, footnote = undefined) {
        const editorElement = document.createElement('editor-element');
        const idPrefix = 'editor-element-footnote-item';
        const elementId = generateId(editor, idPrefix);

        editorElement.setAttribute('type', 'footnote-item');
        editorElement.setAttribute('id', elementId);
        editorElement.setAttribute('contentEditable', 'false');

        const markContainer = document.createElement('div');
        markContainer.className = 'mark';
        markContainer.setAttribute('contentEditable', 'false');
        editorElement.appendChild(markContainer);

        const textContainer = document.createElement('div');
        textContainer.className = 'text';
        textContainer.setAttribute('contentEditable', 'true');
        textContainer.innerHTML = footnote ?? ZERO_WIDTH_NB_CHAR;
        editorElement.appendChild(textContainer);

        return editorElement;
    }

    /**
     * @param footnoteElement {HTMLElement}
     * @param mark {string}
     * @param footnote {string | undefined}
     * @param foreignLetterAccent {string | undefined}
     * @return {HTMLElement}
     */
    addFootnoteItem(
        footnoteElement,
        mark,
        footnote = '',
        foreignLetterAccent = undefined,
    ) {
        const element = this.createEditorElement(this.editor);
        const markContainer = element.querySelector('.mark');
        markContainer.innerText = mark + ' ';

        const textContainer = element.querySelector('.text');
        textContainer.innerText = footnote ?? ZERO_WIDTH_NB_CHAR;

        const footnoteElementTextContainer =
            footnoteElement.querySelector('.text');
        footnoteElementTextContainer.appendChild(element);

        if (foreignLetterAccent) {
            element.setAttribute('data-foreign-accent', foreignLetterAccent);
        }

        element.after(createInvisibleParagraphBreak());
        return element;
    }

    getElementByForeignLetterAccent(page, foreignLetterAccent) {
        return page.querySelector(
            `[type="${this.getEditorElementType()}"][data-foreign-accent="${foreignLetterAccent.replaceAll('"', '"')}"]`,
        );
    }

    /**
     * @return {boolean}
     */
    insertElementAtCursor() {
        throw new Error('This element cannot be inserted at cursor.');
    }

    /**
     * @param container {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getElementsInContainer(container) {
        return [
            ...container.querySelectorAll(
                'editor-element[type="footnote-item"]',
            ),
        ];
    }

    /**
     * @param footnoteElement {HTMLElement}
     * @param page {HTMLElement}
     */
    reorderElements(footnoteElement, page) {
        for (const [i, element] of this.getElementsInContainer(
            page,
        ).entries()) {
            const id = element.getAttribute('id');
            const markContainer = element.querySelector('.mark');
            markContainer.innerText = '(**' + (!i ? '' : i + 1) + ') ';

            const elementsMarks =
                this.getEditorElementFootnoteMark().getElementsByItemId(
                    id,
                    page,
                );

            for (const elementMark of elementsMarks) {
                const elementFootnoteMarkTextContainer =
                    elementMark.querySelector('.text');
                elementFootnoteMarkTextContainer.innerText =
                    markContainer.innerText;
            }
        }
    }

    /**
     * @param page {HTMLElement}
     * @param element {HTMLElement}
     * @return {boolean}
     */
    removeOrphan(page, element) {
        const elementId = element.getAttribute('id');
        const parentElement = page.querySelector(
            `[data-item-element=${elementId}]`,
        );
        if (!parentElement) {
            removeInvisibleParagraphBreak(element);
            element.remove();
            return true;
        }
        return false;
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElement(element) {
        const page = getClosestPage(element);
        if (page && this.removeOrphan(page, element)) {
            return;
        }

        let textContainer = element.querySelector('.text');
        if (!textContainer) {
            const newElement = this.createEditorElement();
            element.replaceWith(newElement);
        } else {
            if (!textContainer.textContent) {
                textContainer.innerHTML = ZERO_WIDTH_NB_CHAR;
            }

            let markContainer = element.querySelector('.mark');
            if (!markContainer) {
                markContainer = document.createElement('div');
                markContainer.className = 'mark';
                markContainer.setAttribute('contentEditable', 'false');
                textContainer.before(markContainer);
            }
        }
        fixInvisibleParagraphBreak(element);
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        const rawOutput = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );
        const ignoreElement = flags.includes(
            BrailleFacilConversionFlag.IGNORE_CUSTOM_ELEMENT,
        );

        const markContainer = element.querySelector('.mark');
        const textContainer = element.querySelector('.text');

        const mark = markContainer.textContent
            .replaceAll('(', '`(')
            .replaceAll(')', '`)');

        let brailleData = convertElementToBraille(
            document.createTextNode(mark),
            editorElements,
            brailleDocument,
            [...flags],
        );

        brailleData += convertElementToBraille(
            textContainer,
            editorElements,
            brailleDocument,
            [...flags],
        );

        brailleData = markLines(brailleData, MARK_CHAR.RECOIL_BLOCK);

        if (!ignoreElement) {
            clearEditorElementLinesCount(element);
        }

        const { breaks, paragraphs } = getBrailleParagraphs(
            brailleData,
            brailleDocument,
        );

        if (!rawOutput) {
            let txtData = extractRecursively(
                document.createTextNode(mark),
                flags,
                editorElements,
                brailleDocument,
            );

            txtData += extractRecursively(
                textContainer,
                flags,
                editorElements,
                brailleDocument,
            );
            const textParagraphs = backPropagateBreaksToText(txtData, breaks);

            return textParagraphs.join('\r\n');
        }

        if (!ignoreElement) {
            breakParagraphsEditorElementContainer(element, breaks, true);
            setEditorElementLinesCount(element, paragraphs.length);
        }
        return markLines(paragraphs, MARK_CHAR.RAW_DATA).join('\r\n');
    }

    /**
     * @returns {string[]}
     */
    getContextMenu() {
        return [];
    }
}

registerEditorElement(EDITOR_ELEMENT_FOOTNOTE, new EditorElementFootnote());
registerEditorElement(
    EDITOR_ELEMENT_FOOTNOTE_MARK,
    new EditorElementFootnoteMark(),
);
registerEditorElement(
    EDITOR_ELEMENT_FOOTNOTE_ITEM,
    new EditorElementFootnoteItem(),
);
