import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { showBrailleInGrid } from '../edit-document/editor-mods/modules/core/BrailleView';
import './FiguresTable.scss';

/**
 * @param records {FiguresDto[] | null}
 * @param loadingRow {Element | null}
 * @param selected {number | null}
 * @param showPreviewBraille {boolean | null}
 * @param onSelected {function(FiguresDto)}
 * @return {JSX.Element}
 * @constructor
 */

function FiguresTable({
    records,
    loadingRow,
    selected,
    onSelected,
    showPreviewBraille,
}) {
    useEffect(() => {
        if (records?.length && showPreviewBraille) generateAllPreviews();
    });

    const generateAllPreviews = () => {
        for (const record of records) {
            generateBrailleView(`${record.id}`, record.figureBraille);
        }
    };

    const generateBrailleView = (elementId, converted) => {
        const previewElement = document.getElementById(elementId);

        const splited = converted.split('\r\n');
        const maxColumn = Math.max(...splited.map((str) => str.length));

        previewElement.style.gridTemplateRows = `repeat(${splited.length}, 12px)`;
        previewElement.style.gridTemplateColumns = `repeat(${maxColumn}, 12px)`;

        showBrailleInGrid(splited, previewElement, {
            brailleCellColCount: maxColumn,
            brailleCellRowCount: splited.length,
        });
    };

    return (
        <table className={'selectable'}>
            <thead>
                <tr>
                    {showPreviewBraille && <th>{'Preview braille'}</th>}
                    <th>{'Tags'}</th>
                </tr>
            </thead>
            <tbody>
                {records?.map((figure) => (
                    <tr
                        key={figure.id}
                        onClick={() => {
                            onSelected(figure);
                        }}
                        className={`${
                            selected === figure.id ? 'selected' : ''
                        }`}
                    >
                        {showPreviewBraille && (
                            <td>
                                <div
                                    className={'preview-braille'}
                                    id={`${figure.id}`}
                                />
                            </td>
                        )}
                        {/* I18N */}
                        <td className={'tags'}>{figure.tags?.join(', ')}</td>
                    </tr>
                ))}
                {loadingRow && (
                    <tr>
                        <td colSpan={6}>{loadingRow}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

FiguresTable.propTypes = {
    records: PropTypes.arrayOf(PropTypes.object),
    loadingRow: PropTypes.node,
    selected: PropTypes.number,
    onSelected: PropTypes.func.isRequired,
};

export default FiguresTable;
