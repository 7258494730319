import FieldText from '../components/FieldText';
import './IdentificationModalForm.scss';
import PropTypes from 'prop-types';
import FieldCheckbox from '../components/FieldCheckbox';

/**
 * @param formData {FormData | null}
 * @param onSave {function()}
 * @param onFormUpdate {function(FormData | null)}
 * @returns {JSX.Element}
 * @constructor
 */
function IdentificationModalForm({ formData, onSave, onFormUpdate }) {
    const clearForm = () => {
        onFormUpdate(null);
    };

    const TextAlignmentButtons = () => {
        const setTextAlignment = (alignment) => {
            onFormUpdate({
                textAlignment: alignment,
            });
        };

        return (
            <div className={'identification-modal__text-alignment__buttons'}>
                {['left', 'center', 'right'].map((alignment) => (
                    <span
                        key={alignment}
                        className={`identification-modal__text-alignment__buttons--${alignment}`}
                        data-selected={formData.textAlignment === alignment}
                        onClick={() => setTextAlignment(alignment)}
                    />
                ))}
            </div>
        );
    };

    return (
        <>
            <form className={'condensed-controls'}>
                <section className={'modal-form'}>
                    <div className={'form-itens'}>
                        <div className={'gd-inner'}>
                            <div className={'gd-col gd-col-5--desktop'}>
                                <div className={'gd-inner'}>
                                    <div
                                        className={
                                            'gd-col gd-col-2--desktop gd-col--valign-bottom'
                                        }
                                    >
                                        <FieldText
                                            // I18N
                                            label={'Conteúdo'}
                                            placeholder={'Digite aqui'}
                                            required={true}
                                            value={formData?.value ?? ''}
                                            onChange={(e) =>
                                                onFormUpdate({
                                                    value: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'gd-inner'}>
                            <div
                                className={
                                    'gd-col gd-col-5--desktop identification-modal__text-alignment'
                                }
                            >
                                <label className={'field-label'}>
                                    {'Formatação'}
                                </label>
                                <TextAlignmentButtons />
                            </div>
                        </div>
                        <div className={'gd-inner'}>
                            <div className={'gd-col gd-col-5--desktop'}>
                                <div
                                    className={
                                        'identification-modal__checkboxes'
                                    }
                                >
                                    <div>
                                        <FieldCheckbox
                                            inputs={(() => [
                                                {
                                                    // I18N
                                                    label: 'Suprimir identificação nesta página',
                                                    checked:
                                                        formData?.suppress ??
                                                        false,
                                                    onChange: (e) => {
                                                        const checked =
                                                            e.target.checked;
                                                        onFormUpdate({
                                                            suppress: checked,
                                                        });
                                                    },
                                                },
                                            ])()}
                                        />
                                        <FieldCheckbox
                                            inputs={(() => [
                                                {
                                                    // I18N
                                                    label: 'Alterar em todo o documento',
                                                    checked:
                                                        formData?.affectAllPages ??
                                                        false,
                                                    onChange: (e) => {
                                                        const checked =
                                                            e.target.checked;
                                                        onFormUpdate({
                                                            affectAllPages:
                                                                checked,
                                                        });
                                                    },
                                                },
                                            ])()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={'controls'}>
                    <button
                        className={'button'}
                        onClick={(e) => {
                            e.preventDefault();
                            clearForm();
                        }}
                    >
                        {'Cancelar'}
                    </button>
                    <button
                        className={'button primary'}
                        onClick={(e) => {
                            e.preventDefault();
                            onSave();
                        }}
                    >
                        {'Salvar'}
                    </button>
                </div>
            </form>
        </>
    );
}

IdentificationModalForm.propTypes = {
    formData: PropTypes.shape({
        value: PropTypes.string,
        suppress: PropTypes.bool,
        textAlignment: PropTypes.string,
        affectAllPages: PropTypes.bool,
    }),
    onSave: PropTypes.func.isRequired,
    onFormUpdate: PropTypes.func.isRequired,
};

export default IdentificationModalForm;
