import CloseModalButton from '../components/modal/CloseModalButton';
import { useEffect, useState } from 'react';
import './PaginationModal.scss';
import PropTypes from 'prop-types';
import { EDITOR_ELEMENT_HEADER } from '../edit-document/editor-mods/modules/core/editor-element/EditorElementHeader';
import FieldText from '../components/FieldText';
import IntegerInput from '../components/IntegerInput';
import FieldInteger from '../components/FieldInteger';

/**
 * @typedef {object} FormData
 * @property {string | null | undefined} inkPagination
 * @property {string | null | undefined} braillePagination
 */

/**
 * @typedef {object} FormError
 * @property {string | null | undefined} inkPagination
 * @property {string | null | undefined} braillePagination
 */

/**
 * @param show {boolean}
 * @param onClose {function}
 * @param editor {EditorCustom}
 * @returns {JSX.Element}
 * @constructor
 */
function PaginationModal({ show, onClose, editor }) {
    /**
     * @type {FormData | null}
     */
    const formDataInitialValue = null;
    const [formData, setFormData] = useState(formDataInitialValue);
    const initialFormError = {};
    const [formError, setFormError] = useState(initialFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);

    useEffect(() => {
        if (!editor) return;
        if (validateOnChange) hasValidationError();
    }, [formData]);

    /**
     * @param formData {FormData | null}
     */
    function updateFormData(formData) {
        if (!formData) {
            setFormData(null);
        } else {
            setFormData((prevState) => {
                return { ...prevState, ...formData };
            });
        }
    }

    /**
     * @param formData {FormData}
     * @return {{hasErrors: boolean, errors: FormError}}
     */
    function hasValidationErrorDocument(formData) {
        let errors = {};
        let hasErrors = false;

        if (
            !formData?.inkPagination ||
            formData?.inkPagination?.trim() === ''
        ) {
            errors.inkPagination = 'Campo obrigatório.';
            hasErrors = true;
        }

        if (
            !formData?.braillePagination ||
            formData?.braillePagination.trim() === ''
        ) {
            errors.braillePagination = 'Campo obrigatório.';
            hasErrors = true;
        }

        return { hasErrors, errors };
    }

    // I18N
    const modalTitle = 'Paginação';

    function hasValidationError(document = true) {
        const { hasErrors, errors } = hasValidationErrorDocument(formData);
        if (document) setValidateOnChange(true);
        setFormError(errors);
        return hasErrors;
    }

    async function save() {
        if (hasValidationError()) return;

        if (!editor) return console.error('"editor" is missing');

        const identification = {
            inkPagination: formData.inkPagination,
            braillePagination: formData.braillePagination,
        };

        const { editorElements } = editor.custom.coreModule;
        const editorElementHeader = editorElements.getEditorElementInstance(
            EDITOR_ELEMENT_HEADER,
        );

        editorElementHeader.insertElementAtCursor(editor, {
            identification,
        });

        clearForm();
        onClose();
    }

    const clearForm = () => {
        updateFormData(null);
    };

    return (
        <div
            className={`modal default-modal pagination-modal ${show ? 'show' : ''}`}
        >
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={onClose} />
                <h2>{modalTitle}</h2>
                <div className={'gd-inner table-form-container'}>
                    <div className={'gd-col gd-col-4--desktop'}>
                        <div className={'form-container'}>
                            <form className={'condensed-controls'}>
                                <section className={'modal-form'}>
                                    <div className={'form-itens'}>
                                        <div className={'gd-inner'}>
                                            <div
                                                className={
                                                    'gd-col gd-col-2--desktop'
                                                }
                                            >
                                                <FieldInteger
                                                    // I18N
                                                    label={
                                                        'Númeração inicial - tinta'
                                                    }
                                                    placeholder={'Digite aqui'}
                                                    required={true}
                                                    validationError={
                                                        formError.inkPagination
                                                    }
                                                    value={
                                                        formData?.inkPagination ??
                                                        ''
                                                    }
                                                    onChange={(e) =>
                                                        updateFormData({
                                                            inkPagination:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div
                                                className={
                                                    'gd-col gd-col-2--desktop'
                                                }
                                            >
                                                <FieldInteger
                                                    // I18N
                                                    label={
                                                        'Númeração inicial - braille'
                                                    }
                                                    placeholder={'Digite aqui'}
                                                    required={true}
                                                    validationError={
                                                        formError.braillePagination
                                                    }
                                                    value={
                                                        formData?.braillePagination ??
                                                        ''
                                                    }
                                                    onChange={(e) =>
                                                        updateFormData({
                                                            braillePagination:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div
                                    className={'gd-col--align-between controls'}
                                >
                                    <div>
                                        <button
                                            className={'button'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                clearForm();
                                                onClose();
                                            }}
                                        >
                                            {'Cancelar'}
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            className={'button primary'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                save();
                                            }}
                                        >
                                            {'Salvar'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

PaginationModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

export default PaginationModal;
