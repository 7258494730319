import FieldCheckbox from '../components/FieldCheckbox';
import FieldTextArea from '../components/FieldTextArea';
import FieldTag from '../components/FieldTag';
import './FiguresModalForm.scss';
import PropTypes from 'prop-types';

/**
 * @param formData {FormData | null}
 * @param formError {FormError}
 * @param onSave {function()}
 * @param onRemove {function()}
 * @param onFormUpdate {function(FormData | null)}
 * @param onGeneratePreview {function()}
 * @param saveLoading {boolean}
 * @param removeLoading {boolean}
 * @returns {JSX.Element}
 * @constructor
 */
function FiguresModalForm({
    formData,
    formError,
    onSave,
    onRemove,
    onGeneratePreview,
    onFormUpdate,
    saveLoading,
    removeLoading,
}) {
    const editing = !!formData;

    const clearForm = () => {
        onFormUpdate(null);
    };

    return (
        <>
            <form className={'condensed-controls'}>
                <label className={'field-label'}>
                    {/*I18N*/}
                    {'Formulário'}
                </label>
                <section className={'modal-form'}>
                    <div className={'form-itens'}>
                        <div className={'gd-inner'}>
                            <div className={'gd-col gd-col-5--desktop'}>
                                <FieldCheckbox
                                    inputs={(() => [
                                        {
                                            // I18N
                                            label: 'Nova figura',
                                            checked:
                                                formData?.newFigure ?? false,
                                            onChange: (e) => {
                                                const checked =
                                                    e.target.checked;
                                                onFormUpdate({
                                                    newFigure: checked,
                                                    figure: '',
                                                    caption: '',
                                                    tags: [],
                                                });
                                            },
                                        },
                                    ])()}
                                />
                            </div>
                        </div>
                        <div className={'gd-inner'}>
                            <div className={'gd-col gd-col-5--desktop'}>
                                <div className={'gd-inner'}>
                                    <div
                                        className={
                                            'gd-col gd-col-5--desktop gd-col--valign-bottom'
                                        }
                                    >
                                        <FieldTextArea
                                            // I18N
                                            label={'Figura'}
                                            required={true}
                                            disabled={!editing}
                                            validationError={formError.figure}
                                            value={formData?.figure ?? ''}
                                            rows={20}
                                            onChange={(e) => {
                                                onFormUpdate({
                                                    figure: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'gd-inner'}>
                            <div className={'gd-col gd-col-5--desktop'}>
                                <div className={'gd-inner'}>
                                    <div
                                        className={
                                            'gd-col gd-col-5--desktop gd-col--valign-bottom'
                                        }
                                    >
                                        <FieldTextArea
                                            // I18N
                                            label={'Legenda'}
                                            required={true}
                                            disabled={!editing}
                                            validationError={formError.caption}
                                            value={formData?.caption ?? ''}
                                            rows={10}
                                            onChange={(e) => {
                                                onFormUpdate({
                                                    caption: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'gd-inner'}>
                            <div className={'gd-col gd-col-5--desktop'}>
                                <div className={'gd-inner'}>
                                    <div
                                        className={
                                            'gd-col gd-col-5--desktop gd-col--valign-bottom'
                                        }
                                    >
                                        <FieldTag
                                            // I18N
                                            label={'Tags'}
                                            validationError={formError.tags}
                                            disabled={!editing}
                                            value={formData?.tags || []}
                                            onChange={(e) => {
                                                onFormUpdate({
                                                    tags: e,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {editing && (
                    <div className={'gd-col--align-between controls'}>
                        <div>
                            <button
                                className={'button'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    clearForm();
                                }}
                            >
                                {'Cancelar'}
                            </button>
                            <button
                                className={'button primary'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onGeneratePreview();
                                }}
                            >
                                {'Preview braille'}
                            </button>
                            {formData && !formData.newFigure && (
                                <button
                                    className={`button ${removeLoading ? 'loading' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onRemove();
                                    }}
                                >
                                    {'Remover'}
                                </button>
                            )}
                        </div>
                        <div>
                            <button
                                className={`button primary ${saveLoading ? 'loading' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onSave();
                                }}
                            >
                                {formData.newFigure ? 'Cadastrar' : 'Editar'}
                            </button>
                        </div>
                    </div>
                )}
            </form>
        </>
    );
}

FiguresModalForm.propTypes = {
    formData: PropTypes.shape({
        newFigure: PropTypes.bool,
        figure: PropTypes.string,
        figureBraille: PropTypes.string,
        caption: PropTypes.string,
        tags: PropTypes.array,
    }),
    formError: PropTypes.shape({
        figure: PropTypes.string,
        figureBraille: PropTypes.string,
        caption: PropTypes.string,
        tags: PropTypes.array,
    }),
    onSave: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onFormUpdate: PropTypes.func.isRequired,
    saveLoading: PropTypes.bool,
    removeLoading: PropTypes.bool,
};

export default FiguresModalForm;
