import CloseModalButton from '../components/modal/CloseModalButton';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as FigureService from '../services/FigureService';
import FiguresModalList from './FiguresModalList';
import FiguresModalForm from './FiguresModalForm';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { useContext } from 'react';
import { SaveFigureValidationErrorEnumTxt } from 'plataforma-braille-common';
import './FiguresModal.scss';
import PropTypes from 'prop-types';
import { convertElementToBraille } from '../conversion/braille/HtmlToBraille';
import { EditorElements } from '../edit-document/editor-mods/modules/core/EditorElements';
import { showBrailleInGrid } from '../edit-document/editor-mods/modules/core/BrailleView';

/**
 * @typedef {object} FormData
 * @property {boolean | null | undefined} R
 * @property {string} figure
 * @property {string} figureBraille
 * @property {string | null | undefined} caption
 * @property {string[] | null | undefined} tags
 */

/**
 * @typedef {object} FormError
 * @property {string | null | undefined} figure
 * @property {string | null | undefined} figureBraille
 */

/**
 * @param show {boolean}
 * @param onClose {function}
 * @returns {JSX.Element}
 * @constructor
 */
function FiguresModal({ show, onClose }) {
    const { setConfirmModal, setInfoModal, backendConnectionError } =
        useContext(EnvironmentContext);

    /**
     * @type {React.MutableRefObject<FiguresModalListFunctions | null>}
     */
    const figuresModalListRef = useRef(null);
    /**
     * @type {FormData | null}
     */
    const formDataInitialValue = null;
    const [formData, setFormData] = useState(formDataInitialValue);
    const initialFormError = {};
    const [formError, setFormError] = useState(initialFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    /**
     * @type {string | null}
     */
    const [selectedFigure, setSelectedFigure] = useState(null);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    /**
     * @param formData {FormData | null}
     */
    function updateFormData(formData) {
        if (!formData) {
            setFormData(null);
        } else {
            setFormData((prevState) => {
                if (
                    prevState?.newFigure === true &&
                    formData?.newFigure === false
                ) {
                    return null;
                }
                const newState = { ...prevState, ...formData };

                return newState;
            });
        }
        if (formData?.newFigure) {
            setSelectedFigure(null);
        }
    }

    const getConvertedBraille = () => {
        const convertedElement = document.getElementById(
            'braille-converted-element',
        );

        if (convertedElement) {
            return convertElementToBraille(
                convertedElement,
                new EditorElements(null),
                {},
            );
        }
    };

    const clearPreviewBraille = () => {
        const previewElement = document.getElementById(
            'braille-preview-element',
        );
        if (previewElement) previewElement.innerHTML = '';
    };

    const handleGenerateImage = () => {
        const previewElement = document.getElementById(
            'braille-preview-element',
        );

        const converted = getConvertedBraille();
        const splited = converted.split('\r\n');
        const maxColumn = Math.max(...splited.map((str) => str.length));

        previewElement.style.gridTemplateRows = `repeat(${splited.length}, 12px)`;
        previewElement.style.gridTemplateColumns = `repeat(${maxColumn}, 12px)`;

        showBrailleInGrid(splited, previewElement, {
            brailleCellColCount: maxColumn,
            brailleCellRowCount: splited.length,
        });
    };

    /**
     * @param formData {FormData}
     * @return {{hasErrors: boolean, errors: FormError}}
     */
    function hasValidationErrorDocument(formData) {
        let errors = {};
        let hasErrors = false;

        if (!!formData && (!formData.figure || formData.figure.trim() === '')) {
            errors.figure = 'Campo obrigatório.';
            hasErrors = true;
        }

        return { hasErrors, errors };
    }

    // I18N
    const modalTitle = 'Figuras';

    function hasValidationError(document = true) {
        const { hasErrors, errors } = hasValidationErrorDocument(formData);
        if (document) setValidateOnChange(true);
        setFormError(errors);
        return hasErrors;
    }

    async function fetchFigures() {
        figuresModalListRef.current?.refresh();
    }

    async function save() {
        if (hasValidationError()) return;

        let title;
        const figures = {
            figure: formData.figure,
            figureBraille: getConvertedBraille(),
            caption: formData.caption,
            tags: formData.tags,
        };

        setSaveLoading(true);
        try {
            let message;
            if (formData.newFigure) {
                // I18N
                title = 'Nova figura';
                // I18N
                message = 'Figura adicionada com sucesso!';
                await FigureService.createFigure(figures);
            } else {
                // I18N
                title = 'Editar figura';
                // I18N
                message = 'Figura atualizada com sucesso!';
                await FigureService.editFigure(selectedFigure, figures);
            }

            setInfoModal({
                title,
                message,
                show: true,
                onClose: () => {
                    fetchFigures().then();
                    updateFormData(null);
                    setSelectedFigure(null);
                },
            });
        } catch (e) {
            backendConnectionError(
                'Fail to save figure',
                e,
                null,
                title,
                SaveFigureValidationErrorEnumTxt,
            );
        } finally {
            setSaveLoading(false);
        }
    }

    function remove() {
        setConfirmModal({
            // I18N
            title: 'Remover figura',
            message:
                'Deseja realmente remover a figura? <strong>Essa operação não poderá ser desfeita</strong>.',
            show: true,
            onConfirm: async () => {
                setRemoveLoading(true);
                try {
                    await FigureService.deleteFigure(selectedFigure);
                    setInfoModal({
                        // I18N
                        title: 'Remover figura',
                        // I18N
                        message: 'Figura removida com sucesso!',
                        show: true,
                        onClose: () => {
                            fetchFigures().then();
                            updateFormData(null);
                            setSelectedFigure(null);
                        },
                    });
                } catch (e) {
                    backendConnectionError('Fail to remove figure', e);
                } finally {
                    setRemoveLoading(false);
                }
            },
        });
    }

    const documentForm = useMemo(() => {
        return (
            <>
                <FiguresModalForm
                    formError={formError}
                    formData={formData}
                    onSave={save}
                    onRemove={remove}
                    onGeneratePreview={handleGenerateImage}
                    onFormUpdate={updateFormData}
                    saveLoading={saveLoading}
                    removeLoading={removeLoading}
                />

                {formData && (
                    <>
                        <div id={'braille-converted-element'}>
                            {formData.figure?.split('\n').map((text, index) => (
                                <React.Fragment key={index}>
                                    {text}
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>

                        <div id={'braille-preview-element'}></div>
                    </>
                )}
            </>
        );
    }, [formData, formError, saveLoading, removeLoading]);

    return (
        <div
            className={`modal default-modal figure-modal ${show ? 'show' : ''}`}
        >
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={onClose} />
                <h2>{modalTitle}</h2>
                <div className={'gd-inner table-form-container'}>
                    <div className={'gd-col gd-col-5--desktop'}>
                        <div className={'list-container'}>
                            <FiguresModalList
                                ref={figuresModalListRef}
                                selected={selectedFigure}
                                onSelected={(selected) => {
                                    clearPreviewBraille();
                                    setSelectedFigure(selected.id);
                                    setFormData({
                                        newFigure: false,
                                        id: selected.id,
                                        caption: selected.caption,
                                        figure: selected.figure,
                                        figureBraille: selected.figureBraille,
                                        tags: selected.tags,
                                    });
                                }}
                                showingModal={show}
                            />
                        </div>
                    </div>
                    <div className={'gd-col gd-col-5--desktop'}>
                        <div className={'form-container'}>{documentForm}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

FiguresModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

export default FiguresModal;
