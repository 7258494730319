import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import {
    generateId,
    getClosestEditorElement,
    getClosestEditorElementRecoil,
    getSelectedParagraphs,
    isInsideEditorElementRecoil,
} from '../EditorUtil';
import {
    createInvisibleParagraphBreak,
    elementCanBeInsertedAtSelection,
    fixInvisibleParagraphBreak,
    breakParagraphsEditorElementContainer,
    setEditorElementLinesCount,
    clearEditorElementLinesCount,
    markLines,
    isElementContinuation,
    hasElementContinuation,
    checkAndRepairContinuation,
} from '../EditorElements';
import { BrailleFacilConversionFlag } from '../../../../../conversion/txt/BrailleFacilConversionFlag';
import { registerEditorElement } from './Instances';
import { MARK_CHAR } from '../../../../../conversion/braille/CharMap';
import {
    backPropagateBreaksToText,
    convertElementToBraille,
    removeParagraphBreaks,
} from '../../../../../conversion/braille/HtmlToBraille';
import { getBrailleParagraphs } from '../BrailleView';
import { getCaretPosition } from '../CaretPath';
import { extractRecursively } from '../../../../../conversion/txt/HtmlToBrailleFacil';
import { getBrailleLinesElements } from '../../ExcessLinesControlModule';

export const EDITOR_ELEMENT_RECOIL = 'EDITOR_ELEMENT_RECOIL';

/**
 * @param editor {EditorCustom | null}
 * @param selection {string | null | undefined}
 * @returns {HTMLElement}
 */
export function createEditorElementRecoil(editor, selection = null) {
    const editorElement = document.createElement('editor-element');
    const elementId = generateId(editor, 'editor-element-recoil');
    editorElement.setAttribute('contentEditable', 'false');
    editorElement.setAttribute('type', 'recoil');
    editorElement.setAttribute('id', elementId);

    const recoilText = document.createElement('div');
    recoilText.className = 'text';
    recoilText.setAttribute('contentEditable', 'true');
    recoilText.innerHTML = selection?.trim()
        ? selection.trim()
        : ZERO_WIDTH_NB_CHAR;
    editorElement.appendChild(recoilText);

    return editorElement;
}

/**
 * @implements {EditorElement}
 */
export class EditorElementRecoil {
    constructor() {}

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'recoil';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementRecoil(node);
    }

    /**
     * @returns {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.text'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isBlockingElement() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @return {HTMLElement}
     */
    createEditorElement(editor = null) {
        return createEditorElementRecoil(editor);
    }

    /**
     * @param editor {EditorCustom}
     * @return {boolean}
     */
    insertElementAtCursor(editor) {
        const editorElementRecoil = getClosestEditorElementRecoil(
            editor.selection.getNode(),
        );

        if (editorElementRecoil) {
            // remove recoil
            editor.undoManager.transact(() => {
                /**
                 * @type {Element | Node}
                 */
                const fragment = document.createDocumentFragment();
                /**
                 * @type {HTMLDivElement}
                 */
                const container = editorElementRecoil.querySelector('.text');
                removeParagraphBreaks(container, true);
                for (const child of [...container.childNodes]) {
                    fragment.appendChild(child);
                }
                /**
                 * @type {HTMLBRElement}
                 */
                const br = document.createElement('br');
                fragment.appendChild(br);
                editorElementRecoil.replaceWith(fragment);
                editor.focus();
                editor.selection.setCursorLocation(container, 0);
            });
            return true;
        }

        let editorElement = createEditorElementRecoil(editor);
        if (!elementCanBeInsertedAtSelection(editor, editorElement)) {
            return false;
        }

        editor.undoManager.transact(() => {
            const id = editorElement.getAttribute('id');
            const selectedParagraphs = getSelectedParagraphs(editor);
            if (selectedParagraphs.length !== 1 || selectedParagraphs.length) {
                // has content
                const contentFragment = document.createDocumentFragment();
                for (const [i, paragraph] of selectedParagraphs.entries()) {
                    const br =
                        paragraph[paragraph.length - 1]?.nextElementSibling;
                    if (br?.tagName === 'BR') {
                        br.remove();
                    }
                    for (const node of paragraph) {
                        contentFragment.appendChild(node);
                    }
                    if (i < selectedParagraphs.length - 1) {
                        contentFragment.appendChild(
                            document.createElement('br'),
                        );
                    }
                }
                editorElement
                    .querySelector('.text')
                    ?.appendChild(contentFragment);
            }
            editor.selection.setContent(editorElement.outerHTML);
            editorElement = editor.dom.get(id);
            editorElement.after(createInvisibleParagraphBreak());
            const textContext = editorElement.querySelector('.text');
            /**
             * @type {Text}
             */
            const cursorMark = document.createTextNode(ZERO_WIDTH_NB_CHAR);
            textContext.appendChild(cursorMark);
            editor.focus();
            editor.selection.setCursorLocation(cursorMark, 0);

            /**
             * @type {PageDataChangedEvent}
             */
            const pageDataChangedEvent = {
                caretPosition: getCaretPosition(editor),
            };
            editor.fire('pageDataChanged', pageDataChangedEvent);
        });

        return true;
    }

    /**
     * @param container {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getElementsInContainer(container) {
        return [...container.querySelectorAll('editor-element[type="recoil"]')];
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElement(element) {
        let textContainer = element.querySelector('.text');
        if (!textContainer) {
            const textContainer = document.createElement('div');
            textContainer.className = 'text';
            textContainer.setAttribute('contentEditable', 'true');
            element.appendChild(textContainer);
        }
        if (!textContainer.textContent.trim()) {
            textContainer.textContent = ZERO_WIDTH_NB_CHAR;
        }
        fixInvisibleParagraphBreak(element);
        checkAndRepairContinuation(element);
    }

    /**
     * @return {string[]}
     */
    getContextMenu(element) {
        const editorElement = getClosestEditorElement(element);
        let contextMenu = [];
        if (!isElementContinuation(editorElement)) {
            contextMenu.push('customContextMenuAddParagraphBreakAbove');
        }
        if (!hasElementContinuation(editorElement)) {
            contextMenu.push('customContextMenuAddParagraphBreakBellow');
        }
        contextMenu.push('|', 'customContextMenuRemove');

        return contextMenu;
    }

    /**
     * @returns {boolean}
     */
    supportExcessLinesBetweenPages() {
        return true;
    }

    /**
     * @param editor {EditorCustom}
     * @param element {HTMLElement}
     * @param anotherPageContainer {HTMLElement | null}
     * @param atEnd {boolean}
     * @param lineCount {number}
     * @return {HTMLElement}
     */
    getExcessElement(editor, element, anotherPageContainer, lineCount, atEnd) {
        const textContainer = element.querySelector('.text');
        if (!anotherPageContainer) {
            anotherPageContainer = this.createEditorElement(editor);
        }
        const nextPageTextContainer =
            anotherPageContainer.querySelector('.text');
        const elementsLines = getBrailleLinesElements(textContainer);
        const documentFragment = document.createDocumentFragment();
        let i;
        if (atEnd) {
            i = elementsLines.length - lineCount;
        } else {
            i = 0;
        }
        for (
            let count = 0;
            count < lineCount && i < elementsLines.length;
            count++, i++
        ) {
            const elements = elementsLines[i];
            for (const element of elements) {
                documentFragment.append(element);
            }
        }
        if (atEnd) {
            nextPageTextContainer.prepend(documentFragment);
        } else {
            nextPageTextContainer.append(documentFragment);
        }
        const lineCountAfter = getBrailleLinesElements(textContainer).length;
        setEditorElementLinesCount(element, lineCountAfter);
        if (!lineCountAfter) {
            element.remove();
        }
        return anotherPageContainer;
    }

    /**
     * @param element {HTMLElement}
     * @return {boolean}
     */
    isEmpty(element) {
        const textContainer = element.querySelector('.text');
        return textContainer.textContent === '';
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        const rawOutput = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );
        const ignoreElement = flags.includes(
            BrailleFacilConversionFlag.IGNORE_CUSTOM_ELEMENT,
        );

        const container = element.querySelector('.text');

        let brailleData = convertElementToBraille(
            container,
            editorElements,
            brailleDocument,
            [...flags],
        );

        if (isElementContinuation(element)) {
            brailleData =
                MARK_CHAR.EMPTY_CHAR + MARK_CHAR.EMPTY_CHAR + brailleData;
        }

        brailleData = markLines(brailleData, MARK_CHAR.RECOIL_BLOCK);

        let { breaks, paragraphs } = getBrailleParagraphs(
            brailleData,
            brailleDocument,
        );

        if (!rawOutput) {
            let txtData = extractRecursively(
                container,
                flags,
                editorElements,
                brailleDocument,
            );

            const textParagraphs = backPropagateBreaksToText(txtData, breaks);

            let output = '<R+>\r\n';
            if (isElementContinuation(element)) {
                output += '  ';
            }

            output += textParagraphs.join('\r\n') + '\r\n';
            output += '<R->';
            return output;
        }

        if (!ignoreElement) {
            clearEditorElementLinesCount(element);
        }

        if (!ignoreElement) {
            breakParagraphsEditorElementContainer(container, breaks);
            setEditorElementLinesCount(element, paragraphs.length);
        }
        return markLines(paragraphs, MARK_CHAR.RAW_DATA).join('\r\n');
    }
}

registerEditorElement(EDITOR_ELEMENT_RECOIL, new EditorElementRecoil());
