import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import {
    generateId,
    getClosestTextNode,
    getSelectedParagraphs,
    isEditorElement,
} from '../EditorUtil';
import {
    breakParagraphsEditorElementContainer,
    clearEditorElementLinesCount,
    createInvisibleParagraphBreak,
    elementCanBeInsertedAtSelection,
    fixInvisibleParagraphBreak,
    markLines,
    setEditorElementLinesCount,
} from '../EditorElements';
import { registerEditorElement } from './Instances';
import {
    centerText,
    extractRecursively,
} from '../../../../../conversion/txt/HtmlToBrailleFacil';
import { addIcon } from '../../menu/MenuModule';
import {
    EditorElementHeadingStyle1,
    EditorElementHeadingStyle1_2x,
    EditorElementHeadingStyle2,
    EditorElementHeadingStyle2_2x,
    EditorElementHeadingStyle3,
    EditorElementHeadingStyle3_2x,
    EditorElementHeadingStyle4,
    EditorElementHeadingStyle4_2,
    EditorElementHeadingStyle5,
    EditorElementHeadingStyle5_2x,
} from '../../../../../components/images';
import { BrailleFacilConversionFlag } from '../../../../../conversion/txt/BrailleFacilConversionFlag';
import { getBrailleParagraphs } from '../BrailleView';
import { MARK_CHAR } from '../../../../../conversion/braille/CharMap';
import {
    convertElementToBraille,
    getBrailleDataRealLength,
} from '../../../../../conversion/braille/HtmlToBraille';
import { EDITOR_ELEMENT_RECOIL } from './EditorElementRecoil';
export const EDITOR_ELEMENT_HEADING_1 = 'EDITOR_ELEMENT_HEADING_1';
export const EDITOR_ELEMENT_HEADING_2 = 'EDITOR_ELEMENT_HEADING_2';
export const EDITOR_ELEMENT_HEADING_3 = 'EDITOR_ELEMENT_HEADING_3';
export const EDITOR_ELEMENT_HEADING_4 = 'EDITOR_ELEMENT_HEADING_4';
export const EDITOR_ELEMENT_HEADING_5 = 'EDITOR_ELEMENT_HEADING_5';

/**
 * @param editor {EditorCustom}
 * @param element {HTMLElement}
 * @return {undefined}
 */
export function focusOnCurrentEditorElementHeading(editor, element) {
    /**
     * @type {HTMLElement | null}
     */
    let fieldToFocus = null;
    const headingStyle = Number(element.getAttribute('type').split('-')[1]);
    switch (headingStyle) {
        case 1:
            fieldToFocus = element.querySelector('.text-2');
            break;
        case 2:
        case 3:
        case 4:
        case 5:
            fieldToFocus = element.querySelector('.text');
            break;
    }
    const text = getClosestTextNode(fieldToFocus?.lastChild);
    if (text) {
        editor.selection.setCursorLocation(text, text.textContent.length);
    }
}

/**
 * @param editor {EditorCustom}
 * @returns {[{onAction: *, text: string, type: string, icon: string}]}
 */
export function getSubmenuItems(editor) {
    addIcon(
        editor,
        'customEditorHeading1',
        EditorElementHeadingStyle1,
        EditorElementHeadingStyle1_2x,
    );
    addIcon(
        editor,
        'customEditorHeading2',
        EditorElementHeadingStyle2,
        EditorElementHeadingStyle2_2x,
    );
    addIcon(
        editor,
        'customEditorHeading3',
        EditorElementHeadingStyle3,
        EditorElementHeadingStyle3_2x,
    );
    addIcon(
        editor,
        'customEditorHeading4',
        EditorElementHeadingStyle4,
        EditorElementHeadingStyle4_2,
    );
    addIcon(
        editor,
        'customEditorHeading5',
        EditorElementHeadingStyle5,
        EditorElementHeadingStyle5_2x,
    );

    const { editorElements } = editor.custom.coreModule;
    return [
        {
            type: 'menuitem',
            // I18N
            text: 'Título 1',
            icon: 'customEditorHeading1',
            onAction: function () {
                editorElements.insertElementAtCursor(EDITOR_ELEMENT_HEADING_1);
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Título 2',
            icon: 'customEditorHeading2',
            onAction: function () {
                editorElements.insertElementAtCursor(EDITOR_ELEMENT_HEADING_2);
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Título 3',
            icon: 'customEditorHeading3',
            onAction: function () {
                editorElements.insertElementAtCursor(EDITOR_ELEMENT_HEADING_3);
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Título 4',
            icon: 'customEditorHeading4',
            onAction: function () {
                editorElements.insertElementAtCursor(EDITOR_ELEMENT_HEADING_4);
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Título 5',
            icon: 'customEditorHeading5',
            onAction: function () {
                editorElements.insertElementAtCursor(EDITOR_ELEMENT_HEADING_5);
            },
        },
    ];
}

/**
 * @param editor {EditorCustom}
 * @return {undefined}
 */
export function removeCurrentEditorElementHeading(editor) {
    const element = getClosestEditorElementHeading(editor.selection.getNode());
    editor.dom.remove(element);
    editor.focus();
}

/**
 * @typedef HeadingStyle {(1|2|3|4|5)}
 */

/**
 * @param editor {EditorCustom | undefined | null}
 * @param headingStyle {HeadingStyle}
 * @return {HTMLElement}
 */
export function createEditorElementHeading(editor = null, headingStyle) {
    /**
     * @type {HTMLElement}
     */
    const editorElement = document.createElement('editor-element');
    const idPrefix = 'editor-element-heading';
    const elementId = generateId(editor, idPrefix);
    editorElement.setAttribute('contentEditable', 'false');
    editorElement.setAttribute('type', `heading-${headingStyle}`);
    editorElement.setAttribute('id', elementId);
    editorElement.setAttribute('data-heading-style', String(headingStyle));

    const containerDiv = document.createElement('div');
    containerDiv.setAttribute('class', 'container');
    containerDiv.setAttribute('contentEditable', 'false');

    const valueDiv = document.createElement('div');
    valueDiv.setAttribute('class', 'text');
    valueDiv.setAttribute('contentEditable', 'true');
    valueDiv.innerText = ZERO_WIDTH_NB_CHAR;
    containerDiv.appendChild(valueDiv);

    switch (headingStyle) {
        case 1:
            const secondValueDiv = document.createElement('div');
            secondValueDiv.setAttribute('class', 'text-2');
            secondValueDiv.setAttribute('contentEditable', 'true');
            secondValueDiv.innerText = ZERO_WIDTH_NB_CHAR;
            containerDiv.appendChild(createInvisibleParagraphBreak());
            containerDiv.appendChild(secondValueDiv);
            break;
        case 2:
            break;
        case 3:
            const emDashDiv = document.createElement('div');
            emDashDiv.setAttribute('class', 'em-dash');
            emDashDiv.setAttribute('contentEditable', 'false');
            emDashDiv.innerHTML = '—&nbsp';
            containerDiv.insertBefore(emDashDiv, containerDiv.firstChild);
            break;
        case 4:
            break;
        case 5:
            break;
    }

    editorElement.appendChild(containerDiv);
    return editorElement;
}

/**
 * @param node {HTMLElement | Node}
 * @param headingStyle {HeadingStyle | undefined}
 * @returns {boolean}
 */
export function isEditorElementHeading(node, headingStyle = null) {
    if (!node) return false;
    const headingType = `heading${headingStyle ? `-${headingStyle}` : ''}`;
    return (
        isEditorElement(node) &&
        node.getAttribute('type')?.startsWith(headingType)
    );
}

/**
 * @param node {HTMLElement | Node | null}
 * @param headingStyle {HeadingStyle | undefined}
 * @returns {boolean}
 */
export function isInsideEditorElementHeading(node, headingStyle = null) {
    if (!node) return false;
    let walk = node;
    while (walk) {
        if (isEditorElementHeading(walk, headingStyle)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @param node {HTMLElement | Node | null}
 * @returns {HTMLElement | null}
 */
export function getClosestEditorElementHeading(node) {
    if (!node) return null;
    let walk = node;
    while (walk) {
        if (isEditorElementHeading(walk)) return walk;
        walk = walk.parentNode;
    }
    return node;
}

/**
 * @param editor {EditorCustom}
 * @param editorElement {HTMLElement}
 * @param headingStyle {HeadingStyle}
 * @returns {undefined}
 */
function insertNewElementAtCursor(editor, editorElement, headingStyle) {
    /**
     * @type {Node}
     */
    const documentFragment = document.createDocumentFragment();
    documentFragment.appendChild(editorElement);
    documentFragment.appendChild(createInvisibleParagraphBreak());
    /**
     * @type {Node}
     */
    const valueFragment = document.createDocumentFragment();
    /**
     * @type {Node}
     */
    const secondValueFragment = document.createDocumentFragment();
    const selectedParagraphs = getSelectedParagraphs(editor);
    if (selectedParagraphs.length) {
        for (const [
            paragraphIndex,
            paragraph,
        ] of selectedParagraphs.entries()) {
            const br = paragraph[paragraph.length - 1]?.nextElementSibling;
            if (br?.tagName === 'BR') {
                br.remove();
            }
            for (let node of paragraph) {
                switch (headingStyle) {
                    case 1:
                        if (paragraphIndex === 0) {
                            valueFragment.appendChild(node);
                        } else {
                            secondValueFragment.appendChild(node);
                        }
                        break;
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        valueFragment.appendChild(node);
                        break;
                }
            }
            if (paragraphIndex < selectedParagraphs.length - 1) {
                /**
                 * @type {HTMLElement}
                 */
                const br = document.createElement('br');
                switch (headingStyle) {
                    case 1:
                        if (paragraphIndex !== 0) {
                            secondValueFragment.appendChild(br);
                        }
                        break;
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        valueFragment.appendChild(br);
                        break;
                }
            }
        }
        editorElement.querySelector('.text')?.appendChild(valueFragment);
        editorElement
            .querySelector('.text-2')
            ?.appendChild(secondValueFragment);
    }
    editor.selection.setNode(documentFragment);
}

/**
 * @param oldEditorElementHeading {HTMLElement}
 * @param oldEditorElementHeadingSize {HeadingStyle}
 * @param editorElement {HTMLElement}
 * @param headingSize {HeadingStyle}
 * @returns {undefined}
 */
function changeElementAtCursor(
    oldEditorElementHeading,
    oldEditorElementHeadingSize,
    editorElement,
    headingSize,
) {
    /**
     * @type {Node}
     */
    const valueFragment = document.createDocumentFragment();
    /**
     * @type {Node}
     */
    const secondValueFragment = document.createDocumentFragment();
    const oldValue = oldEditorElementHeading.querySelector('.text');
    switch (headingSize) {
        case 1:
            Array.from(oldValue.childNodes).forEach((node, index) => {
                if (index === 0) {
                    valueFragment.appendChild(node.cloneNode(true));
                } else {
                    if (index === 1 && node.tagName === 'BR') {
                        return;
                    }
                    secondValueFragment.appendChild(node.cloneNode(true));
                }
            });
            editorElement
                .querySelector('.text-2')
                ?.appendChild(secondValueFragment);
            break;
        case 2:
        case 3:
        case 4:
        case 5:
            Array.from(oldValue.childNodes).forEach((node) => {
                valueFragment.appendChild(node.cloneNode(true));
            });
            break;
    }

    switch (oldEditorElementHeadingSize) {
        case 1:
            const oldSecondValue =
                oldEditorElementHeading.querySelector('.text-2');
            if (oldSecondValue.textContent.trim()) {
                /**
                 * @type {Node}
                 */
                const br = document.createElement('br');
                valueFragment.appendChild(br);
                Array.from(oldSecondValue.childNodes).forEach((node) => {
                    valueFragment.appendChild(node.cloneNode(true));
                });
            }
            break;
        case 2:
        case 3:
        case 4:
        case 5:
            break;
    }
    editorElement.querySelector('.text')?.appendChild(valueFragment);
    oldEditorElementHeading.replaceWith(editorElement);
}

/**
 * @implements {EditorElement}
 */
class EditorElementHeading {
    exportText = true;
    exportText2 = true;
    exportLine = true;

    /**
     * @param headingStyle {HeadingStyle}
     */
    constructor(headingStyle) {
        this.headingStyle = headingStyle;
    }

    /**
     * @return {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.text', '.text-2'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isBlockingElement() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @return {HTMLElement}
     */
    createEditorElement(editor = null) {
        return createEditorElementHeading(editor, this.headingStyle);
    }

    /**
     * @param {EditorCustom} editor
     * @return {boolean}
     */
    insertElementAtCursor(editor) {
        const selectedNode = editor.selection.getNode();
        const oldEditorElementHeading =
            getClosestEditorElementHeading(selectedNode);
        const oldEditorElementHeadingSize = Number(
            oldEditorElementHeading.getAttribute('data-heading-style'),
        );
        let editorElement = this.createEditorElement(editor);

        if (!isInsideEditorElementHeading(selectedNode)) {
            if (!elementCanBeInsertedAtSelection(editor, editorElement)) {
                return false;
            }
        } else {
            if (oldEditorElementHeadingSize === this.headingStyle) {
                return false;
            }
        }

        editor.undoManager.transact(() => {
            if (isInsideEditorElementHeading(selectedNode)) {
                changeElementAtCursor(
                    oldEditorElementHeading,
                    oldEditorElementHeadingSize,
                    editorElement,
                    this.headingStyle,
                );
            } else {
                insertNewElementAtCursor(
                    editor,
                    editorElement,
                    this.headingStyle,
                );
            }
            editor.focus();
            editorElement = editor.dom.get(editorElement.getAttribute('id'));
            const valueDiv = editorElement?.querySelector('.text');
            if (valueDiv) {
                const text = getClosestTextNode(valueDiv.lastChild);
                if (text) {
                    editor.selection.setCursorLocation(
                        text,
                        text.textContent.length,
                    );
                } else {
                    editor.selection.setCursorLocation(valueDiv, 0);
                }
            }
        });

        return true;
    }

    /**
     * @param container {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getElementsInContainer(container) {
        return [
            ...container.querySelectorAll(
                `editor-element[type="heading-${this.headingStyle}"]`,
            ),
        ];
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElement(element) {
        /**
         * @type {HTMLElement}
         */
        let text = element.querySelector('.text');
        if (!text) {
            text = document.createElement('div');
            text.className = 'text';
            text.contentEditable = 'true';
            text.innerHTML = ZERO_WIDTH_NB_CHAR;
            element.querySelector('.container')?.appendChild(text);
        }
        if (!text.innerText.trim().length) {
            text.innerHTML = ZERO_WIDTH_NB_CHAR;
        }

        switch (this.headingStyle) {
            case 1:
                /**
                 * @type {HTMLElement}
                 */
                let secondValue = element.querySelector('.text-2');
                if (!secondValue) {
                    secondValue = document.createElement('div');
                    secondValue.className = 'text-2';
                    secondValue.contentEditable = 'true';
                    secondValue.innerHTML = ZERO_WIDTH_NB_CHAR;
                    element
                        .querySelector('.container')
                        ?.appendChild(secondValue);
                }
                if (!secondValue.innerText.trim().length) {
                    secondValue.innerHTML = ZERO_WIDTH_NB_CHAR;
                }
                break;
            default:
                break;
        }

        if (!element.getAttribute('data-heading-style')) {
            element.setAttribute(
                'data-heading-style',
                String(this.headingStyle),
            );
        }

        fixInvisibleParagraphBreak(element);
    }

    /**
     * @returns {string[]}
     */
    getContextMenu() {
        return [
            'customContextMenuAddParagraphBreakAbove',
            'customContextMenuAddParagraphBreakBellow',
            '|',
            'customContextMenuRemove',
        ];
    }
}

export class EditorElementHeading1 extends EditorElementHeading {
    constructor() {
        super(1);
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementHeading(node, 1);
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'heading-1';
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    generateTxt(element, flags, editorElements, brailleDocument) {
        const textContainer = element.querySelector('.text');
        const text2Container = element.querySelector('.text-2');

        const text = this.exportText
            ? extractRecursively(
                  textContainer,
                  flags,
                  editorElements,
                  brailleDocument,
              )
            : null;

        const text2 = this.exportText2
            ? extractRecursively(
                  text2Container,
                  flags,
                  editorElements,
                  brailleDocument,
              )
            : null;

        let txt = '';
        let lineWidth = 0;
        function setTextMaxLength(text) {
            const cleanedText = text.replace(/[\r\n]/g, '');
            if (cleanedText.length > lineWidth) {
                lineWidth = cleanedText.length;
            }
        }

        if (this.exportText) {
            for (let paragraph of text.split('\r\n')) {
                if (!paragraph.length) continue;
                txt += centerText(paragraph) + '\r\n';
                setTextMaxLength(paragraph);
            }
        }
        if (this.exportText2) {
            for (let paragraph of text2.split('\r\n')) {
                setTextMaxLength(paragraph);
            }
        }
        lineWidth += 2;
        if (lineWidth > brailleDocument.brailleCellColCount) {
            lineWidth = brailleDocument.brailleCellColCount;
        }
        if (this.exportLine) {
            txt += centerText(':'.repeat(lineWidth)) + '\r\n';
        }
        if (this.exportText2) {
            for (let paragraph of text2.split('\r\n')) {
                if (!paragraph.length) continue;
                txt += centerText(paragraph) + '\r\n';
            }
        }
        return txt;
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    generateBraille(element, flags, editorElements, brailleDocument) {
        const ignoreElement = flags.includes(
            BrailleFacilConversionFlag.IGNORE_CUSTOM_ELEMENT,
        );

        const textContainer = element.querySelector('.text');
        const text2Container = element.querySelector('.text-2');

        if (!ignoreElement) {
            clearEditorElementLinesCount(element);
        }

        const textBrailleData = this.exportText
            ? markLines(
                  convertElementToBraille(
                      textContainer,
                      editorElements,
                      brailleDocument,
                      flags,
                  ).trim(),
                  MARK_CHAR.CENTER_BLOCK,
              )
            : null;

        const text2BrailleData = this.exportText2
            ? markLines(
                  convertElementToBraille(
                      text2Container,
                      editorElements,
                      brailleDocument,
                      flags,
                  ).trim(),
                  MARK_CHAR.CENTER_BLOCK,
              )
            : null;

        let breaksText = null;
        let paragraphsText = null;
        if (this.exportText) {
            const { breaks, paragraphs } = getBrailleParagraphs(
                textBrailleData,
                brailleDocument,
            );
            breaksText = breaks;
            paragraphsText = paragraphs;
        }

        let breaksText2 = null;
        let paragraphsText2 = null;
        if (this.exportText2) {
            const { breaks, paragraphs } = getBrailleParagraphs(
                text2BrailleData,
                brailleDocument,
            );
            breaksText2 = breaks;
            paragraphsText2 = paragraphs;
        }

        if (!ignoreElement) {
            if (textContainer)
                breakParagraphsEditorElementContainer(
                    textContainer,
                    breaksText,
                );
            if (text2Container)
                breakParagraphsEditorElementContainer(
                    text2Container,
                    breaksText2,
                );
            let lineCount = 0;
            if (paragraphsText) lineCount += paragraphsText.length;
            if (this.exportLine) lineCount++;
            if (paragraphsText2) lineCount += paragraphsText2.length;
            setEditorElementLinesCount(element, lineCount);
        }

        let lineWidth = 0;
        for (const line of [
            ...(paragraphsText ?? []),
            ...(paragraphsText2 ?? []),
        ]) {
            const width = getBrailleDataRealLength(line.trim());
            if (width > lineWidth) {
                lineWidth = width;
            }
        }

        lineWidth += 2;
        if (lineWidth > brailleDocument.brailleCellColCount) {
            lineWidth = brailleDocument.brailleCellColCount;
        }

        const paragraphs = [];
        if (paragraphsText) paragraphs.push(...paragraphsText);
        if (this.exportLine) {
            const line =
                MARK_CHAR.EMPTY_CHAR.repeat(
                    (brailleDocument.brailleCellColCount - lineWidth) / 2,
                ) + ':'.repeat(lineWidth);
            paragraphs.push(line);
        }
        if (paragraphsText2) paragraphs.push(...paragraphsText2);

        return markLines(paragraphs, MARK_CHAR.RAW_DATA).join('\r\n');
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        if (flags.includes(BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT)) {
            return this.generateBraille(
                element,
                flags,
                editorElements,
                brailleDocument,
            );
        } else {
            return this.generateTxt(
                element,
                flags,
                editorElements,
                brailleDocument,
            );
        }
    }
}

export class EditorElementHeading2 extends EditorElementHeading1 {
    constructor() {
        super();
        this.headingStyle = 2;
        this.exportText2 = false;
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'heading-2';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementHeading(node, 2);
    }
}

export class EditorElementHeading3 extends EditorElementHeading {
    constructor() {
        super(3);
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'heading-3';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementHeading(node, 3);
    }

    convertToBraille(element, flags, editorElements, brailleDocument) {
        const rawOutput = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );
        const ignoreElement = flags.includes(
            BrailleFacilConversionFlag.IGNORE_CUSTOM_ELEMENT,
        );

        const container = element.querySelector('.text');

        if (!rawOutput) {
            let txt = '';
            let text = extractRecursively(
                container,
                flags,
                editorElements,
                brailleDocument,
            );

            for (let [index, paragraph] of text.split('\n').entries()) {
                if (!paragraph.length) continue;
                if (index === 0) {
                    txt += '-- ' + paragraph + '\n';
                } else {
                    txt += '\uFEFF'.repeat(3) + paragraph + '\n';
                }
            }
            return txt;
        }

        if (!ignoreElement) {
            clearEditorElementLinesCount(element);
        }

        let brailleData =
            '-- ' +
            convertElementToBraille(
                container,
                editorElements,
                brailleDocument,
                flags,
            );
        brailleData = markLines(brailleData, MARK_CHAR.RECOIL_BLOCK);
        const { breaks, paragraphs } = getBrailleParagraphs(
            brailleData,
            brailleDocument,
        );

        if (!ignoreElement) {
            breakParagraphsEditorElementContainer(element, breaks, true);
            setEditorElementLinesCount(element, paragraphs.length);
        }

        return markLines(paragraphs, MARK_CHAR.RAW_DATA).join('\r\n');
    }
}

export class EditorElementHeading4 extends EditorElementHeading1 {
    constructor() {
        super();
        this.headingStyle = 4;
        this.exportLine = false;
        this.exportText2 = false;
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'heading-4';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementHeading(node, 4);
    }
}

export class EditorElementHeading5 extends EditorElementHeading {
    constructor() {
        super(5);
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'heading-5';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementHeading(node, 5);
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        const editorElementRecoil = editorElements.getEditorElementInstance(
            EDITOR_ELEMENT_RECOIL,
        );
        /**
         * This works because the element structure is identical to recoil
         * If it changes, this should be revised
         */
        return editorElementRecoil.convertToBraille(
            element,
            flags,
            editorElements,
            brailleDocument,
        );
    }
}

registerEditorElement(EDITOR_ELEMENT_HEADING_1, new EditorElementHeading1());
registerEditorElement(EDITOR_ELEMENT_HEADING_2, new EditorElementHeading2());
registerEditorElement(EDITOR_ELEMENT_HEADING_3, new EditorElementHeading3());
registerEditorElement(EDITOR_ELEMENT_HEADING_4, new EditorElementHeading4());
registerEditorElement(EDITOR_ELEMENT_HEADING_5, new EditorElementHeading5());
