import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import {
    generateId,
    getRootNodeInPage,
    getClosestTextNode,
    isEditorElement,
    isEditorPage,
} from '../EditorUtil';
import {
    breakParagraphsEditorElementContainer,
    clearEditorElementLinesCount,
    createInvisibleParagraphBreak,
    elementCanBeInsertedAtSelection,
    fixInvisibleParagraphBreak,
    markLines,
    setEditorElementLinesCount,
} from '../EditorElements';
import { getCaretPosition } from '../CaretPath';
import { registerEditorElement } from './Instances';
import { extractRecursively } from '../../../../../conversion/txt/HtmlToBrailleFacil';
import { BrailleFacilConversionFlag } from '../../../../../conversion/txt/BrailleFacilConversionFlag';
import { convertElementToBraille } from '../../../../../conversion/braille/HtmlToBraille';
import { getBrailleParagraphs } from '../BrailleView';
import { MARK_CHAR } from '../../../../../conversion/braille/CharMap';

export const EDITOR_ELEMENT_LIST = 'EDITOR_ELEMENT_LIST';

/**
 * @typedef {object} Data
 * @property {('•'|'→'|'▢')} symbol
 */

/**
 * @param node {HTMLElement | Node | null}
 * @returns {HTMLElement | null}
 */
export function getClosestEditorElementList(node) {
    if (!node) return null;
    let walk = node;
    while (walk) {
        if (isEditorElementList(walk)) return walk;
        walk = walk.parentNode;
    }
    return node;
}

/**
 * @param editor {EditorCustom}
 * @param element {HTMLElement | ChildNode}
 * @return {undefined}
 */
export function focusOnCurrentEditorElementList(editor, element) {
    const value = element.querySelector('.value');
    const text = getClosestTextNode(value.lastChild);
    if (text) {
        editor.selection.setCursorLocation(text, text.textContent.length);
    }
}

/**
 * @param editor {EditorCustom}
 * @return {undefined}
 */
export function insertEditorElementListOnEnterPressed(editor) {
    const element = getClosestEditorElementList(editor.selection.getNode());
    const symbol = element
        .querySelector('.symbol')
        ?.textContent.replace(/\u00A0/g, '');
    const { editorElements } = editor.custom.coreModule;
    const editorElementList =
        editorElements.getEditorElementInstance(EDITOR_ELEMENT_LIST);
    editorElementList.insertElementAtCursor(editor, { symbol });
}

/**
 * @param editor {EditorCustom}
 * @return {undefined}
 */
export function removeCurrentEditorElementList(editor) {
    const element = getClosestEditorElementList(editor.selection.getNode());
    editor.dom.remove(element);
    editor.focus();
}

/**
 * @param editor {EditorCustom}
 * @returns {[{onAction: *, icon: string, text: string, type: string}]}
 */
export function getSubmenuItems(editor) {
    const { editorElements } = editor.custom.coreModule;
    const editorElementList =
        editorElements.getEditorElementInstance(EDITOR_ELEMENT_LIST);
    return [
        {
            type: 'menuitem',
            // I18N
            text: 'Lista tipo Bullet',
            icon: 'customEditorBullet',
            onAction: function () {
                editorElementList.insertElementAtCursor(editor, {
                    symbol: '•',
                });
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Lista tipo Seta',
            icon: 'customEditorArrowForward',
            onAction: function () {
                editorElementList.insertElementAtCursor(editor, {
                    symbol: '→',
                });
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Lista tipo Quadrado',
            icon: 'customEditorSquare',
            onAction: function () {
                editorElementList.insertElementAtCursor(editor, {
                    symbol: '▢',
                });
            },
        },
    ];
}

/**
 * @param editor {EditorCustom | undefined | null}
 * @param data {Data}
 * @return {HTMLElement}
 */
export function createEditorElementList(editor = null, data) {
    /**
     * @type {HTMLElement}
     */
    const editorElement = document.createElement('editor-element');
    const idPrefix = 'editor-element-list';
    const elementId = generateId(editor, idPrefix);
    editorElement.setAttribute('contentEditable', 'false');
    editorElement.setAttribute('type', 'list');
    editorElement.setAttribute('id', elementId);

    /**
     * @type {HTMLElement}
     */
    const symbolDiv = document.createElement('div');
    symbolDiv.setAttribute('class', 'symbol');
    symbolDiv.setAttribute('contentEditable', 'false');
    symbolDiv.innerHTML = data?.symbol + '&nbsp;';
    editorElement.appendChild(symbolDiv);

    /**
     * @type {HTMLElement}
     */
    const valueDiv = document.createElement('div');
    valueDiv.setAttribute('class', 'value');
    valueDiv.setAttribute('contentEditable', 'true');
    editorElement.appendChild(valueDiv);

    const content = editor.selection.getContent();
    if (content) {
        let selectedElement = editor.selection.getNode();
        while (selectedElement) {
            if (
                isEditorPage(selectedElement) ||
                isEditorPage(selectedElement.parentElement)
            ) {
                break;
            } else {
                selectedElement = selectedElement.parentElement;
            }
        }
        valueDiv.innerHTML = isEditorPage(selectedElement)
            ? content
            : selectedElement.outerHTML;
    } else {
        valueDiv.innerText = ZERO_WIDTH_NB_CHAR;
    }

    return editorElement;
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementList(node) {
    if (!node) return false;
    return (
        isEditorElement(node) &&
        node.getAttribute('type')?.toLowerCase() === 'list'
    );
}

/**
 * @param node {HTMLElement | Node | null}
 * @returns {boolean}
 */
export function isInsideEditorElementList(node) {
    if (!node) return false;
    let walk = node;
    while (walk) {
        if (isEditorElementList(walk)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @implements {EditorElement}
 */
export class EditorElementList {
    constructor() {}

    /**
     * @param editor {EditorCustom}
     */
    initialize(editor) {
        /**
         * @param e {InputEvent}
         */
        const onBeforeInput = (e) => {
            const selectedNode = editor.selection.getNode();
            const isSelectedNodeEmpty = !selectedNode.textContent.trim();

            if (e?.inputType === 'insertLineBreak') {
                if (isInsideEditorElementList(selectedNode)) {
                    e.preventDefault();
                    if (!isSelectedNodeEmpty) {
                        insertEditorElementListOnEnterPressed(editor);
                    } else {
                        removeCurrentEditorElementList(editor);
                    }
                }
            } else if (e?.inputType === 'insertParagraph') {
                if (isInsideEditorElementList(selectedNode)) {
                    editor.focus();
                    const brNode = editor.getDoc().createElement('br');
                    const zeroWidthNode = editor
                        .getDoc()
                        .createTextNode(ZERO_WIDTH_NB_CHAR);
                    editor.selection.getRng().insertNode(zeroWidthNode);
                    editor.selection.getRng().insertNode(brNode);
                    const range = editor.selection.getRng();
                    range.setStartAfter(zeroWidthNode);
                    range.setEndAfter(zeroWidthNode);
                    editor.selection.setRng(range);
                }
            } else if (e?.inputType === 'deleteContentBackward') {
                if (isInsideEditorElementList(selectedNode)) {
                    if (isSelectedNodeEmpty) {
                        e.preventDefault();
                        removeCurrentEditorElementList(editor);
                    }
                } else {
                    const caretPosition = getCaretPosition(editor);
                    if (!caretPosition) return;
                    let previousElement =
                        caretPosition.nodePath[caretPosition.path.length - 1];
                    if (
                        isEditorElementList(previousElement) ||
                        (previousElement?.tagName === 'BR' &&
                            previousElement?.style?.display === 'none' &&
                            isEditorElementList(
                                previousElement?.previousSibling,
                            ))
                    ) {
                        e.preventDefault();
                        focusOnCurrentEditorElementList(
                            editor,
                            previousElement.previousSibling,
                        );
                    }
                }
            }
        };

        editor.on('beforeInput', onBeforeInput);
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'list';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementList(node);
    }

    /**
     * @return {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.value'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isBlockingElement() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @param data {Data}
     * @return {HTMLElement}
     */
    createEditorElement(editor = null, data) {
        return createEditorElementList(editor, data);
    }

    /**
     * @param {EditorCustom} editor
     * @param {Data} data
     * @return {boolean}
     */
    insertElementAtCursor(editor, data) {
        const selectedNode = editor.selection.getNode();
        let editorElement = this.createEditorElement(editor, data);

        if (!isInsideEditorElementList(selectedNode)) {
            if (!elementCanBeInsertedAtSelection(editor, editorElement)) {
                return false;
            }
        }

        editor.undoManager.transact(() => {
            const id = editorElement.getAttribute('id');
            // attach the editor-element at cursor
            if (isEditorPage(selectedNode)) {
                // noinspection JSCheckFunctionSignatures
                editor.selection.setNode(editorElement);
            } else {
                editor.selection.setContent('');
                let insertAt = selectedNode;
                insertAt = getRootNodeInPage(insertAt);

                if (!insertAt) {
                    console.warn(
                        'No container found to insert editor element list.',
                    );
                    return;
                }

                insertAt.replaceWith(insertAt, editorElement);
            }
            editorElement = editor.dom.get(id);
            const valueDiv = editorElement.querySelector('.value');
            editorElement.replaceWith(
                editorElement,
                createInvisibleParagraphBreak(),
            );
            editor.focus();
            const text = getClosestTextNode(valueDiv.lastChild);
            if (text) {
                editor.selection.setCursorLocation(
                    text,
                    text.textContent.length,
                );
            } else {
                editor.selection.setCursorLocation(valueDiv, 0);
            }
        });

        return true;
    }

    /**
     * @param container {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getElementsInContainer(container) {
        return [...container.querySelectorAll('editor-element[type="list"]')];
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElement(element) {
        /**
         * @type {HTMLElement}
         */
        let value = element.querySelector('.value');
        if (!value) {
            value = document.createElement('div');
            value.className = 'value';
            element.innerHTML = ZERO_WIDTH_NB_CHAR;
            element.appendChild(value);
        }
        if (!value.innerText.trim().length) {
            value.innerHTML = ZERO_WIDTH_NB_CHAR;
        }
        fixInvisibleParagraphBreak(element);
    }

    /**
     * @returns {string[]}
     */
    getContextMenu() {
        return [
            'customContextMenuAddParagraphBreakAbove',
            'customContextMenuAddParagraphBreakBellow',
            '|',
            'customContextMenuRemove',
        ];
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        const rawOutput = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );

        const renderBraille = (container) => {
            return rawOutput
                ? convertElementToBraille(
                      container,
                      editorElements,
                      brailleDocument,
                      flags,
                  )
                : extractRecursively(
                      container,
                      flags,
                      editorElements,
                      brailleDocument,
                  );
        };

        const symbolContainer = element.querySelector('.symbol');
        const valueContainer = element.querySelector('.value');
        const brailleSymbolData = renderBraille(symbolContainer);

        clearEditorElementLinesCount(element);

        let columnsLength =
            brailleDocument.brailleCellColCount - 1 - brailleSymbolData.length;

        /**
         * @type {BrailleDocument}
         */
        const brailleDocumentCol = {
            ...brailleDocument,
            brailleCellColCount: columnsLength,
        };

        const brailleValueData = renderBraille(valueContainer);
        let { breaks, paragraphs: resultingParagraphs } = getBrailleParagraphs(
            brailleValueData,
            brailleDocumentCol,
        );

        for (let i = 0; i < resultingParagraphs.length; i++) {
            if (i === 0) {
                resultingParagraphs[i] =
                    brailleSymbolData + resultingParagraphs[i];
            } else {
                resultingParagraphs[i] =
                    ' '.repeat(brailleSymbolData.length) +
                    resultingParagraphs[i];
            }
        }

        if (rawOutput) {
            breakParagraphsEditorElementContainer(valueContainer, breaks);
            setEditorElementLinesCount(element, resultingParagraphs.length);
            resultingParagraphs = markLines(
                resultingParagraphs,
                MARK_CHAR.RAW_DATA,
            );
        } else {
            resultingParagraphs.unshift('<F->');
            resultingParagraphs.push('<F+>');
        }

        return resultingParagraphs.join('\r\n');
    }
}

registerEditorElement(EDITOR_ELEMENT_LIST, new EditorElementList());
