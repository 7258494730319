import Field from './Field';
import { forwardRef, useRef } from 'react';
import nextId from 'react-id-generator';
import './FieldTextArea.scss';

function FieldTextArea(
    {
        children,
        required,
        className,
        value,
        onChange,
        onKeyUp,
        onKeyDown,
        placeholder,
        style,
        disabled,
        rows,
        ...props
    },
    ref,
) {
    const { labelText, validationError } = props;

    const id = props.id ?? nextId('field-textarea');
    const idError = `${id}-error`;
    const fieldRef = useRef();
    let inputStyle = { ...(style ?? {}) };

    return (
        <Field
            ref={fieldRef}
            {...props}
            idInput={id}
            idError={idError}
            bottomChildren={children}
        >
            <textarea
                ref={ref}
                id={id}
                required={!!required}
                aria-required={!!required}
                aria-invalid={!!validationError}
                aria-errormessage={idError}
                style={inputStyle}
                title={labelText}
                value={value ?? ''}
                rows={rows}
                disabled={disabled ?? false}
                placeholder={placeholder}
                onChange={(e) => {
                    if (onChange) onChange(e);
                }}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                className={className || 'field-textarea'}
            />
        </Field>
    );
}

export default forwardRef(FieldTextArea);
