import {
    getBrailleDocument,
    isElementVisible,
    isInsidePage,
} from './core/EditorUtil';
import {
    isShowingNonPrintableChars,
    removeNonPrintableChars,
    showNonPrintableChars,
} from './core/ShowNonPrintableChars';
import { getBrailleView, getPages } from './core/PageManipulation';
import { cachePage, isPageCached, uncachePage } from './core/Cache';

/**
 * @param editor {EditorCustom}
 */
export function updateScroll(editor) {
    if (!editor) return;
    editor.custom?.scrollModule?.onScroll(null);
}

/**
 * @param page {HTMLElement}
 */
export function markPageNeedsUpdate(page) {
    // console.trace('markPageNeedsUpdate', page);
    page.setAttribute('data-needs-update', 'true');
}

export function isPageNeedsUpdate(page) {
    return page.getAttribute('data-needs-update') === 'true';
}

export class ScrollModule {
    /**
     * @type {number | null}
     */
    timerScroll = null;

    /**
     * @type {number | null}
     */
    timerUpdateVisiblePagesIfNeeded = null;

    /**
     * @param editor {EditorCustom}
     */
    constructor(editor) {
        this.editor = editor;
    }

    install() {
        const onScrollFn = (e) => this.onScroll(e);
        this.editor.getDoc().addEventListener('scroll', onScrollFn);
        this.editor.on('documentDestroyed', () => {
            this.editor.getDoc().addEventListener('scroll', onScrollFn);
        });
        this.editor.on('pageDataChanged', () => {
            this.updateVisiblePagesIfNeeded();
        });
    }

    /**
     * @param page {HTMLElement}
     * @returns {boolean}
     */
    isPageVisible(page) {
        return isElementVisible(
            this.editor.getBody().parentElement,
            page,
            this.editor.custom.zoom,
        );
    }

    /**
     * @param e {Event | null}
     */
    onScroll(e) {
        if (this.timerScroll) clearTimeout(this.timerScroll);
        const self = this;
        const coreModule = this.editor.custom.coreModule;

        this.timerScroll = setTimeout(() => {
            try {
                if (!self.editor.getDoc()) return;

                for (const page of getPages(self.editor)) {
                    const cached = isPageCached(page);
                    if (self.isPageVisible(page)) {
                        const pageNeedsUpdate = isPageNeedsUpdate(page);

                        const brailleDocument = getBrailleDocument(self.editor);
                        if (cached === false) {
                            if (
                                brailleDocument.convertedToBraille &&
                                !pageNeedsUpdate
                            ) {
                                coreModule.brailleView.showBrailleView(
                                    page,
                                    true,
                                );
                                continue;
                            }
                        }
                        uncachePage(self.editor, page, true);
                        if (pageNeedsUpdate) {
                            self.updatePage(page);
                        } else {
                            if (brailleDocument.convertedToBraille) {
                                coreModule.brailleView.showBrailleView(
                                    page,
                                    false,
                                );
                            }
                        }
                        if (
                            self.editor.custom.isShowingNonPrintableChars &&
                            !isShowingNonPrintableChars(page)
                        ) {
                            showNonPrintableChars(self.editor, page);
                        }
                    } else {
                        if (cached === true) continue;
                        const { endContainer } = self.editor.selection.getRng();
                        if (isInsidePage(endContainer, page)) {
                            continue;
                        }
                        getBrailleView(page)?.remove();
                        if (self.editor.custom.isShowingNonPrintableChars) {
                            removeNonPrintableChars(page);
                        }
                        cachePage(self.editor, page, true);
                    }
                }
            } finally {
                self.timerScroll = null;
            }
            self.editor.fire('scrollFinished', e);
        }, 50);
    }

    /**
     * @param page {HTMLElement}
     */
    updatePage(page) {
        this.editor.custom.coreModule.updatePage(page);
    }

    updateVisiblePagesIfNeeded() {
        if (this.timerUpdateVisiblePagesIfNeeded) {
            clearTimeout(this.timerUpdateVisiblePagesIfNeeded);
        }

        const self = this;
        this.timerUpdateVisiblePagesIfNeeded = setTimeout(() => {
            for (const page of getPages(self.editor)) {
                try {
                    if (isPageNeedsUpdate(page) && self.isPageVisible(page)) {
                        self.updatePage(page);
                    }
                } finally {
                    self.timerUpdateVisiblePagesIfNeeded = null;
                }
            }
        }, 100);
    }
}
